import { Element } from 'domhandler';

import { ALLOWED_ATTR_CHARS, ALLOWED_ATTRS } from '../../constants';
import { stripHostname } from '../../stripHostname';
import type { AllowedAttr, RichMediaTagTransformer } from '../../types';
import { validateString } from '../../validateString';

export const encodeTransformer: RichMediaTagTransformer = ({ assetBasePath } = {}) => {
	if (!assetBasePath) throw new Error('Asset base path required');

	// eslint-disable-next-line @typescript-eslint/naming-convention
	const additionalEncodeAttributes = { 'data-type': 'img' };

	const shouldTransformElement = (element: Element): boolean => {
		const matchedAttr = element.attributes.find((attribute) => attribute.name === 'src');
		try {
			return new URL(matchedAttr?.value ?? '').href.startsWith(assetBasePath);
		} catch {
			return false;
		}
	};

	const transformTagFn = (element: Element): Element => {
		if (!shouldTransformElement(element)) return element;
		const newTagName = 'change-media';
		const newAttributes = {
			...additionalEncodeAttributes,
			...element.attributes.reduce<Record<string, string>>((newAttrs, attribute) => {
				const matchedName = ALLOWED_ATTRS.find((attrName) => attrName === attribute.name);
				const matchedValue = attribute.name === 'src' ? stripHostname(attribute.value) : attribute.value;
				if (matchedName && validateString(matchedValue, ALLOWED_ATTR_CHARS[attribute.name as AllowedAttr])) {
					return { ...newAttrs, [`data-${attribute.name}`]: matchedValue };
				}
				return newAttrs;
			}, {}),
		};
		return new Element(newTagName, newAttributes, element.children);
	};

	const transformer = { tagName: 'img', transformTagFn };

	return { transformer };
};
