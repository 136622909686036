import type { DateFormats } from '../shared/types';

const COMMON_OPTIONS = { calendar: 'gregory' };

export const DATE_FORMATS: DateFormats = {
	date: {
		short: { ...COMMON_OPTIONS, dateStyle: 'short' },
		medium: { ...COMMON_OPTIONS, dateStyle: 'medium' },
		long: { ...COMMON_OPTIONS, dateStyle: 'long' },
		full: { ...COMMON_OPTIONS, dateStyle: 'full' },
	},
	time: {
		short: { ...COMMON_OPTIONS, timeStyle: 'short' },
		medium: { ...COMMON_OPTIONS, timeStyle: 'medium' },
		long: { ...COMMON_OPTIONS, timeStyle: 'long' },
		// using long here to match previous date-fns format that didn't have ms
		full: { ...COMMON_OPTIONS, timeStyle: 'long' },
	},
	datetime: {
		// using medium here to match previous date-fns format that had seconds
		short: { ...COMMON_OPTIONS, dateStyle: 'short', timeStyle: 'medium' },
		medium: { ...COMMON_OPTIONS, dateStyle: 'medium', timeStyle: 'medium' },
		long: { ...COMMON_OPTIONS, dateStyle: 'long', timeStyle: 'long' },
		// using long here to match previous date-fns format that didn't have ms
		full: { ...COMMON_OPTIONS, dateStyle: 'full', timeStyle: 'long' },
	},
};
