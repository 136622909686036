import type { HtmlTransformer } from '@change-corgi/core/react/html';
import { encodeChangeTags } from '@change-corgi/core/richMediaTags';

import type { RichMediaTransformerParams } from './types';

export class EncodeRichMediaTransformer implements HtmlTransformer {
	private readonly assetBasePath: string;

	constructor({ assetBasePath }: RichMediaTransformerParams) {
		this.assetBasePath = assetBasePath;
	}

	transformHtml = (html: string): string => encodeChangeTags(html, { assetBasePath: this.assetBasePath });
}
