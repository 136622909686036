/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { forwardRef as forwardRefBase } from 'react';

import type { EmptyIntersection } from '@change-corgi/core/types';

/**
 * Wrapping around React's forwardRef with better typings
 */
export const forwardRef: <T, P = EmptyIntersection>(
	render: (props: P, ref: React.Ref<T>) => React.ReactElement | null,
) => (props: P & React.RefAttributes<T>) => React.ReactElement | null = forwardRefBase as any;
