import type Cookies from 'js-cookie';

import { getWindow } from '@change-corgi/core/window';

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export interface IExperimentOverrides {
	all: () => Readonly<Record<string, string>>;
	get: <VARIATION extends string = string>(id: string) => VARIATION | undefined;
	set: <VARIATION extends string = string>(id: string, value: VARIATION | undefined) => this;
	unset: (id: string) => this;
	clear: () => this;
	decorateWindow: () => void;
}

export type Options = Readonly<{
	cookies: Cookies.CookiesStatic;
	/**
	 * Overrides that come from another source than cookies (e.g. query string)
	 *
	 * Those will take precedence over cookies
	 */
	overrides: Readonly<Record<string, string>>;
}>;

class ExperimentsOverrides implements IExperimentOverrides {
	private readonly cookies: Cookies.CookiesStatic;
	private readonly overrides: Record<string, string>;

	constructor({ cookies, overrides }: Options) {
		this.cookies = cookies;
		this.overrides = { ...overrides };
	}

	private setExperimentOverride<VARIATION extends string = string>(name: string, variation: VARIATION | undefined) {
		// as soon as we persist the override, we should remove it from the map so the map doesn't
		// override the cookie value anymore
		delete this.overrides[name];
		const cookieName = `Experiments_${name}`;
		if (!variation) {
			this.cookies.remove(cookieName);
		} else {
			this.cookies.set(cookieName, variation);
		}
	}

	private unsetExperimentOverride(name: string) {
		this.setExperimentOverride(name, undefined);
	}

	private getExperimentOverride<VARIATION extends string = string>(name: string): VARIATION | undefined {
		return (
			(name in this.overrides
				? (this.overrides[name] as VARIATION)
				: (this.cookies.get(`Experiments_${name}`) as VARIATION)) || undefined
		);
	}

	private getExperimentOverridesNames(): readonly string[] {
		const nonCookieNames = Object.keys(this.overrides);
		const cookieNames = Object.keys(this.cookies.get())
			.map((key) => (/^Experiments_(.*)$/.exec(key) || [])[1])
			.filter((name) => !!name && !nonCookieNames.includes(name));
		return [...nonCookieNames, ...cookieNames];
	}

	private getExperimentOverrides(): Readonly<Record<string, string>> {
		return this.getExperimentOverridesNames().reduce<Record<string, string>>((acc, id) => {
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion, no-param-reassign
			acc[id] = this.getExperimentOverride(id)!;
			return acc;
		}, {});
	}

	all(): Readonly<Record<string, string>> {
		return this.getExperimentOverrides();
	}

	get<VARIATION extends string = string>(id: string): VARIATION | undefined {
		if (!id) {
			throw new Error('parameter missing');
		}
		return this.getExperimentOverride<VARIATION>(id);
	}

	// chainable
	set<VARIATION extends string = string>(id: string, value: VARIATION | undefined): this {
		if (!id) {
			throw new Error('parameter missing');
		}
		this.setExperimentOverride<VARIATION>(id, value);
		return this;
	}

	// chainable
	unset(id: string): this {
		if (!id) {
			throw new Error('parameter missing');
		}
		this.unsetExperimentOverride(id);
		return this;
	}

	// chainable
	clear(): this {
		this.getExperimentOverridesNames().forEach((id) => this.unsetExperimentOverride(id));
		return this;
	}

	private getFacade() {
		const facade = {
			all: this.all.bind(this),
			get: this.get.bind(this),
			// chainable
			set: (id: string, value: string | undefined) => {
				this.set(id, value);
				return facade;
			},
			// chainable
			unset: (id: string) => {
				this.unset(id);
				return facade;
			},
			// chainable
			clear: () => {
				this.clear();
				return facade;
			},
		};
		return facade;
	}

	/**
	 * add useful functions to the window object
	 *
	 * @example
	 * window._sov(experimentId, variation)
	 * window._exp.all()
	 * window._exp.get(experimentId)
	 * window._exp.set(experimentId, variation)
	 * window._exp.unset(experimentId)
	 * window._exp.clear()
	 */
	decorateWindow(): void {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const window: any = getWindow();
		if (!window) {
			return;
		}
		const facade = this.getFacade();
		/* eslint-disable @typescript-eslint/no-unsafe-member-access */
		if (!window._sov) {
			// using old name for compatibility with fe (react-fe/client/index.js and rendr-fe/shared/collections/experiment_collection.js)
			// _sov available as global for debugging/QA
			window._sov = facade.set.bind(facade);
		}
		// TODO set new name in old code?
		window._exp = facade;
		/* eslint-enable @typescript-eslint/no-unsafe-member-access */
	}
}

export function createOverrides(options: Options): ExperimentsOverrides {
	return new ExperimentsOverrides(options);
}
