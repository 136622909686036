import { useIsFirstAppRender } from '../context';

type Props = {
	children?: React.ReactNode | (() => React.ReactNode);
	placeholder?: React.ReactNode | (() => React.ReactNode);
};

/**
 * Can be used to render specific content only on the client
 *
 * By default, even if the component is not part of the generated DOM, it will still internally be rendered by React.
 *
 * For further optimization, the children and the placeholder can be functions so that that rendering doesn't happen.
 * This can be especially useful when using features that are not available server-side, or when rendering complex component trees.
 */
export function ClientRender({ children, placeholder }: Props): React.ReactElement | null {
	const firstAppRender = useIsFirstAppRender();

	const result = firstAppRender ? placeholder : children;

	if (!result) return null;
	// casting necessary due to typing issue: https://github.com/DefinitelyTyped/DefinitelyTyped/issues/18051
	// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-return
	return (typeof result === 'function' ? result() : result) as any;
}
