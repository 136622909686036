import type { ReportableError } from '@change-corgi/core/errorReporter/common';

type Options = {
	fetch: typeof fetch;
	reportNetworkError: (error: ReportableError) => void;
};

function getRequestUrl(info: RequestInfo | URL) {
	if (typeof info === 'string') return info;
	if (info instanceof URL) return info.toString();
	return info.url;
}

function getRequestMethod(info: RequestInfo | URL, init?: RequestInit) {
	if (init?.method) return init.method;
	if (typeof info === 'string' || info instanceof URL) return 'GET';
	return info.method;
}

export function wrapFetch({ fetch: originalFetch, reportNetworkError }: Options): typeof fetch {
	// Patch fetch to ensure error includes relevant debugging info
	return async function fetch(info, init) {
		/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-unsafe-argument */
		try {
			const result = await originalFetch(info as any, init);
			return result;
		} catch (e) {
			void reportNetworkError({
				error: e as Error,
				params: {
					url: getRequestUrl(info as any),
					method: getRequestMethod(info as any, init),
				},
			});
			throw e;
		}
		/* eslint-enable @typescript-eslint/no-explicit-any, @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-unsafe-argument */
	};
}
