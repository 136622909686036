const EXTERNAL_REGEX = /^(https?:\/\/|mailto:|instagram:)/;

export function isExternalHttpUrl(url: string): boolean {
	return !!EXTERNAL_REGEX.exec(url);
}

export function isExternalUrl(
	url: string,
	{ internalRoutes, forceMode }: { internalRoutes: readonly RegExp[]; forceMode?: 'internal' | 'external' },
): boolean {
	const [simplifiedUrl] = url.split(/[?#]/g);
	if (isExternalHttpUrl(simplifiedUrl)) {
		return true;
	}
	if (forceMode) {
		return forceMode === 'external';
	}
	return !internalRoutes.some((routeRegExp) => !!routeRegExp.exec(simplifiedUrl));
}
