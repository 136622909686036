import { useContext } from 'react';

import type { PrefetchContextData, PrefetchSessionContextData, PrefetchUserContextData } from './context';
import { PrefetchContext, PrefetchSessionContext, PrefetchUserContext } from './context';

export function usePrefetchedData(): PrefetchContextData {
	return useContext(PrefetchContext);
}

export function usePrefetchedUserData(): PrefetchUserContextData {
	return useContext(PrefetchUserContext);
}

export function usePrefetchedSession(): PrefetchSessionContextData {
	return useContext(PrefetchSessionContext);
}
