type RetryOptions<T> = {
	task: (attemptNumber: number, previousAttemptError?: unknown) => Promise<T>;
	onRetry?: (attemptNumber: number, previousAttemptError?: unknown) => Promise<T>;
	attempts: number;
	delay: number;
};

async function wait(time: number) {
	// eslint-disable-next-line promise/avoid-new
	return new Promise((resolve) => {
		setTimeout(resolve, time);
	});
}

type RetryError = Error & {
	retryAttempts: number;
	retryDelay: number;
};

export async function retry<T>({ task, onRetry, attempts, delay }: RetryOptions<T>): Promise<T> {
	let attemptsRemaining = attempts;

	async function runRetryTask(previousAttemptError?: unknown): Promise<T> {
		try {
			const result = await (attemptsRemaining === attempts
				? task(1)
				: (onRetry || task)(attempts - attemptsRemaining + 1, previousAttemptError));
			return result;
		} catch (e) {
			attemptsRemaining -= 1;
			if (attemptsRemaining > 0) {
				await wait(delay);
				return runRetryTask(e);
			}
			if (e instanceof Error) {
				const errorWithRetryInfo = e as RetryError;
				errorWithRetryInfo.retryAttempts = attempts;
				errorWithRetryInfo.retryDelay = delay;
				throw errorWithRetryInfo;
			} else {
				throw e;
			}
		}
	}

	return runRetryTask();
}
