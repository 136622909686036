import values from 'lodash/fp/values';

import { getWindow } from '@change-corgi/core/window';

import { isReady } from './ready';
import { getOptimizelyInstance } from './script';

type Campaign = {
	isActive?: boolean;
	isInCampaignHoldback?: boolean;
	experiment?: { id: string };
	variation?: { id: string };
};

type Decision = {
	isCampaignHoldback: boolean;
	experimentId: string;
	variationId?: string;
};

type ActiveDecision = {
	isCampaignHoldback: false;
	experimentId: string;
	variationId: string;
};

export function getActiveCampaigns(): Record<string, string> {
	if (!isReady()) return {};

	// get all active campaigns where the user is not in a holdback group and a variation ID is present
	// note that in the campaign states, this property is named `isInCampaignHoldback`,
	// while in `campaignDecided` events it's `isCampaignHoldback`

	// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
	const campaignStates = getWindow().optimizely.get('state').getCampaignStates();
	const campaignStatesArray = values(campaignStates);
	const reducer = (result: Record<string, string>, campaign: Campaign) => {
		if (campaign.isActive && !campaign.isInCampaignHoldback && campaign.experiment?.id && campaign.variation?.id) {
			// eslint-disable-next-line no-param-reassign
			result[campaign.experiment.id] = campaign.variation.id;
		}
		return result;
	};

	return campaignStatesArray.reduce<Record<string, string>>(reducer, {});
}

export function isActiveDecision(decision: Decision): decision is ActiveDecision {
	if (decision.isCampaignHoldback) return false;
	if (!decision.variationId) return false;
	return true;
}

export function listenToActiveDecisions(handler: (decision: ActiveDecision) => void): void {
	// eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
	getOptimizelyInstance().push({
		type: 'addListener',
		filter: {
			type: 'lifecycle',
			name: 'campaignDecided',
		},
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		handler: (event: any) => {
			// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment
			const { decision } = event.data;

			// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
			if (!isActiveDecision(decision)) return;

			handler(decision);
		},
	});
}
