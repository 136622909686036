import { selectAll } from 'css-select';
import render from 'dom-serializer';
import type { Element, Node } from 'domhandler';
import { replaceElement } from 'domutils';
import { parseDocument } from 'htmlparser2';

import type { RichMediaTransformer } from './types';

type TransformTagsParams = {
	html: string;
	transformers: RichMediaTransformer[];
};

export const transformTags = ({ html, transformers }: TransformTagsParams): string =>
	transformers.reduce((transformedHtml, transformer) => {
		const document = parseDocument(transformedHtml);
		const matchingElements = selectAll<Node, Element>(transformer.tagName, document);
		matchingElements.forEach((matchingElement) => {
			const newElement = transformer.transformTagFn(matchingElement);
			replaceElement(matchingElement, newElement);
		});
		return render(document);
	}, html);
