import { getWindowSafe } from '@change-corgi/core/window';

const window = getWindowSafe();

export const KEEPALIVE_SUPPORTED = !!window && !!window.Request && 'keepalive' in new window.Request('');

export function isKeepaliveSupported(): boolean {
	return KEEPALIVE_SUPPORTED;
}

export function isSendBeaconSupported(): boolean {
	return !!window && !!window.navigator?.sendBeacon;
}
