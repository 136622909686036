export type PerimeterXEnforcerChallenge = Readonly<{
	appId: string;
	jsClientSrc: string;
	firstPartyEnabled: boolean;
	vid: string;
	uuid: string; // PerimeterX also calls this a RefId. Don't confuse with _our_ uuid.
	hostUrl: string;
	blockScript: string;
}>;

export type PerimeterXEnforcerChallengeWithBlockedUrl = PerimeterXEnforcerChallenge &
	Readonly<{
		blockedUrl: string;
	}>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export function isPerimeterXEnforcerChallenge(obj: any): obj is PerimeterXEnforcerChallenge {
	// TODO check other fields?
	// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
	return !!obj && !!obj.appId && !!obj.blockScript && !!obj.jsClientSrc;
}

export function getPerimeterXEnforcerChallengeWithBlockedUrl(
	challenge: PerimeterXEnforcerChallenge,
	blockedUrl: string,
): PerimeterXEnforcerChallengeWithBlockedUrl {
	return {
		...challenge,
		blockedUrl,
	};
}

export function isPerimeterXHttpResponse(response: Response): boolean {
	return response.status === 403 && response.headers?.get('x-px-score') === '100';
}
