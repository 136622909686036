type Options = Readonly<{
	allowed: boolean;
	locationMatch?: readonly RegExp[];
	pathname: string;
}>;

export function isOptimizelyEnabled({ allowed, locationMatch, pathname }: Options): boolean {
	if (!allowed) return false;

	if (!locationMatch) return true;

	return locationMatch.some((pageRegExp) => pageRegExp.exec(pathname));
}
