/* eslint-disable @typescript-eslint/naming-convention */
const MAPPING: Record<string, string> = {
	'de-DE': 'de_DE',
	'en-AU': 'en_GB',
	'en-CA': 'en_GB',
	'en-GB': 'en_GB',
	'en-IN': 'en_GB',
	'en-US': 'en_US',
	'es-419': 'es_LA',
	'es-AR': 'es_LA',
	'es-ES': 'es_ES',
	'fr-FR': 'fr_FR',
	'hi-IN': 'hi_IN',
	'id-ID': 'id_ID',
	'it-IT': 'it_IT',
	'ja-JP': 'ja_JP',
	'ko-KR': 'ko_KR',
	'pt-BR': 'pt_BR',
	'pt-PT': 'pt_PT',
	'ru-RU': 'ru_RU',
	'th-TH': 'th_TH',
	'tl-PH': 'tl_PH',
	'tr-TR': 'tr_TR',
	'zh-TW': 'zh_TW',
};

export function getFacebookLocale(locale: string): string | undefined {
	return MAPPING[locale];
}
