import type { PageState } from '@change-corgi/core/pageState';
import { createMandatoryContext } from '@change-corgi/core/react/context';

export const {
	Context: PageStateContext,
	Provider: PageStateProvider,
	useContext: usePageState,
} = createMandatoryContext<PageState>(undefined, {
	name: 'PageStateContext',
});
