import { isPerimeterXHttpResponse } from '@change-corgi/core/perimeterx';

function isCloudFlareError(response: Response) {
	// our graphql middleware adds the x-graphql header
	// if it's missing, it means that the request didn't reach our server
	// and, if there is a cf-ray header, it means that it did reach Cloudflare, which is probably where the request stopped
	// note: x-graphql could also be removed from the response by a proxy, so it's not 100% foolproof
	return !!response.headers?.get('cf-ray') && response.headers?.get('x-graphql') !== 'true';
}

function isRobotsError(response: Response) {
	return response.headers?.get('reason') === 'robots.txt denied';
}

function isProxyError(response: Response) {
	// our graphql middleware adds the x-graphql header
	// if it's missing, it means that the request didn't reach our server
	// note: x-graphql could also be removed from the response by a proxy, so it's not 100% foolproof
	return response.headers?.get('x-graphql') !== 'true';
}

function isCsrfError(response: Response) {
	return response.headers?.get('X-Invalid-CSRF') === 'true';
}

export function getFetchResponseError(response: Response): Error {
	if (isPerimeterXHttpResponse(response)) {
		return new Error(`GraphQL query blocked by PerimeterX (HTTP ${response.status})`);
	}
	if (isCsrfError(response)) {
		return new Error(`GraphQL query blocked by CSRF (HTTP ${response.status})`);
	}
	if (isRobotsError(response)) {
		return new Error(`GraphQL query blocked by robots.txt (HTTP ${response.status})`);
	}
	if (isCloudFlareError(response)) {
		return new Error(`GraphQL query blocked by CloudFlare (HTTP ${response.status})`);
	}
	if (isProxyError(response)) {
		return new Error(`GraphQL query blocked by Proxy or equivalent (HTTP ${response.status})`);
	}
	return new Error(`HTTP ${response.status} Error when running GraphQL query`);
}
