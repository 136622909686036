import { getDocument } from '@change-corgi/core/window';

/**
 * Resolve url from absolute or relative path to full url (with scheme and host)
 * @param url absolute or relative path
 */
export function resolveUrl(url: string): string {
	const a = getDocument().createElement('a');
	a.href = url;
	return a.href;
}
