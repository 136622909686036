import getOr from 'lodash/fp/getOr';

import { MissingTranslationError } from '../shared/errors';
import type { Replacements, TranslationKeyArg, Translations } from '../shared/types';
import type { UsedTranslationsTracker } from '../UsedTranslationsTracker';

import { replace } from './replace';

export function translate(
	{
		translations,
		tracker,
	}: {
		translations: readonly Translations[];
		tracker?: UsedTranslationsTracker;
	},
	key: TranslationKeyArg,
	replacements: Replacements | undefined,
): string {
	const value = translations.reduce<string | Translations | null>(
		(acc, dic) => acc ?? getOr(null, key as string, dic),
		null,
	);
	if (typeof value !== 'string') {
		throw new MissingTranslationError({ key: key as string });
	}
	tracker?.add(key, value);
	return replace(key, value, replacements);
}
