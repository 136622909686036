import { useMemo } from 'react';

import { useUtilityContext, type UtilityContext } from '@change-corgi/core/react/utilityContext';

import { translateHtml, translatePluralHtml } from './html';
import type { TranslateHtmlFn, TranslatePluralHtmlFn } from './html';

export type I18nUtilities = UtilityContext['i18n'] &
	Readonly<{
		translateHtml: TranslateHtmlFn;
		translatePluralHtml: TranslatePluralHtmlFn;
	}>;

export function useI18n(): I18nUtilities {
	const { i18n } = useUtilityContext();

	return useMemo(
		() => ({
			...i18n,
			translateHtml: (key, replacements, options) => translateHtml(i18n.translate, key, replacements, options),
			translatePluralHtml: (key, count, replacements, options) =>
				translatePluralHtml(i18n.translatePlural, key, count, replacements, options),
		}),
		[i18n],
	);
}
