import type { AllowedAttr } from './types';

export const ALLOWED_ATTRS: AllowedAttr[] = ['alt', 'height', 'src', 'width', 'allowfullscreen'];
export const ALLOWED_ATTR_CHARS: Record<AllowedAttr, RegExp> = {
	alt: /^.+$/,
	height: /^\d+$/,
	src: /^((?!\.\.)[\w-./])+$/,
	width: /^\d+$/,
	allowfullscreen: /^.+$/,
};

export const YOUTUBE_PLAYER_SETTINGS = 'cc_lang_pref=en&hl=en&disablekb=1&controls=1&modestbranding=1&rel=0';
export const YOUTUBE_BASE_PATH = 'https://www.youtube.com/embed';
// see: https://gist.github.com/rodrigoborgesdeoliveira/987683cfbfcc8d800192da1e73adc486
export const YOUTUBE_REGEX =
	/(?:http:|https:)*?\/\/(?:www\.|)(?:youtube\.com|m\.youtube\.com|youtu\.|youtube-nocookie\.com).*(?:v=|v%3D|v\/|(?:a|p)\/(?:a|u)\/\d.*\/|watch\?|vi(?:=|\/)|\/embed\/|oembed\?|be\/|e\/)([^&?%#/\n]*)/;
