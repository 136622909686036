import { getDemoEnvironment } from './demoEnvironment';
import { detectEnvironment } from './detectEnvironment';
import type { BuildEnvironment, Environment } from './types';

type Options = Readonly<{
	/**
	 * hostname will be used to determine the environment (if not directly specified)
	 */
	hostname?: string;
	/**
	 * if available (usually server-side), this can be specified instead of hostname
	 */
	environment?: Environment;
	/**
	 * if available (usually server-side), this can be specified instead of hostname
	 */
	demoEnvironment?: string;
	/**
	 * can be used to override the environment
	 *
	 * This can be useful in dev env where the app is proxying to a different environment, e.g. demo or staging
	 *
	 * defaults to what environment ends up being
	 */
	apiEnvironment?: Environment;
	/**
	 * can be used to override the demoEnvironment
	 *
	 * This can be useful in dev env where the app is proxying to a different environment, e.g. demo or staging
	 *
	 * defaults to what demoEnvironment ends up being
	 */
	apiDemoEnvironment?: string | undefined;
	buildEnvironment: BuildEnvironment;
}>;

/* eslint-disable @typescript-eslint/consistent-type-definitions, @typescript-eslint/method-signature-style */
interface EnvironmentUtils {
	getEnvironment(): Environment;
	getDemoEnvironment(): string | undefined;
	getApiEnvironment(): Environment;
	getApiDemoEnvironment(): string | undefined;
	getBuildEnvironment(): BuildEnvironment;
}
/* eslint-enable @typescript-eslint/consistent-type-definitions, @typescript-eslint/method-signature-style */

class EnvironmentUtilsImpl implements EnvironmentUtils {
	private readonly hostname?: string;
	private readonly environment: Environment;
	private readonly demoEnvironment: string | undefined;
	private readonly apiEnvironment: Environment;
	private readonly apiDemoEnvironment: string | undefined;
	private readonly buildEnvironment: BuildEnvironment;

	constructor({
		hostname,
		buildEnvironment,
		demoEnvironment,
		environment,
		apiEnvironment,
		apiDemoEnvironment,
	}: Options) {
		if (!hostname && !environment) {
			throw new Error('Please specify at least hostname or environment');
		}
		this.hostname = hostname;
		this.buildEnvironment = buildEnvironment;
		this.demoEnvironment = demoEnvironment || (this.hostname && getDemoEnvironment(this.hostname));
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		this.environment = environment || detectEnvironment(hostname!, this.buildEnvironment);
		this.apiEnvironment = apiEnvironment || this.environment;
		this.apiDemoEnvironment = apiDemoEnvironment || this.demoEnvironment;
	}

	/**
	 * The environment the app is running on
	 */
	getEnvironment(): Environment {
		return this.environment;
	}

	/**
	 * The demo sub-environment the app is running on
	 */
	getDemoEnvironment(): string | undefined {
		return this.demoEnvironment;
	}

	/**
	 * The environment of the APIs (or third party services) the app is targeting
	 */
	getApiEnvironment(): Environment {
		return this.apiEnvironment;
	}

	/**
	 * The demo sub-environment of the APIs (or third party services) the app is targeting
	 */
	getApiDemoEnvironment(): string | undefined {
		return this.apiDemoEnvironment;
	}

	/**
	 * The build environment the app was built for
	 */
	getBuildEnvironment(): BuildEnvironment {
		return this.buildEnvironment;
	}
}

export type { EnvironmentUtils };

export function createEnvironmentUtils(options: Options): EnvironmentUtils {
	return new EnvironmentUtilsImpl(options);
}

export function createEnvironmentUtilsFake(errorMsg: string): EnvironmentUtils {
	const errorFn = () => {
		throw new Error(errorMsg);
	};
	return {
		getBuildEnvironment: errorFn,
		getDemoEnvironment: errorFn,
		getEnvironment: errorFn,
		getApiDemoEnvironment: errorFn,
		getApiEnvironment: errorFn,
	};
}
