import type { JSX } from 'react';

import type { TranslationKeyArg } from '@change-corgi/core/i18n';

import type { JsxReplacements, TranslateHtmlOptions } from '../misc';
import { useI18n } from '../misc';

type Props = Readonly<{
	value: TranslationKeyArg;
	count: number;
	replacements?: JsxReplacements;
}> &
	TranslateHtmlOptions;

export function TranslatePluralHtml({ value, count, replacements, ...options }: Props): JSX.Element {
	const { translatePluralHtml } = useI18n();

	return <>{translatePluralHtml(value, count, replacements, options)}</>;
}
