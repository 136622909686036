type Unit = 'second' | 'minute' | 'hour' | 'day' | 'week' | 'month' | 'year';

const TIME_IN_SECONDS: Record<Unit, number> = {
	second: 1,
	minute: 60,
	hour: 3600,
	day: 86400,
	week: 604800,
	month: 2629743.83,
	year: 31556926,
};

function getBestUnit(seconds: number): Unit {
	if (seconds < TIME_IN_SECONDS.minute) {
		return 'second';
	}
	if (seconds < TIME_IN_SECONDS.hour) {
		return 'minute';
	}
	if (seconds < TIME_IN_SECONDS.day) {
		return 'hour';
	}
	if (seconds < TIME_IN_SECONDS.week) {
		return 'day';
	}
	if (seconds < TIME_IN_SECONDS.month) {
		return 'week';
	}
	if (seconds < TIME_IN_SECONDS.year) {
		return 'month';
	}
	return 'year';
}

export type RelativeTimeOptions = {
	style?: 'long' | 'short' | 'narrow';
	numeric?: 'always' | 'auto';
};

function dateToNumber(date: number | string | Date): number {
	if (typeof date === 'number') return date;
	if (typeof date === 'string') return new Date(date).getTime();
	return date.getTime();
}

export function localizeRelativeTime(
	locale: string,
	from: number | string | Date,
	to: number | string | Date,
	{ style = 'long', numeric = 'always' }: RelativeTimeOptions = {},
): string {
	const seconds = (dateToNumber(to) - dateToNumber(from)) / 1000;
	const unit = getBestUnit(Math.abs(seconds));
	// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
	return new (Intl as any).RelativeTimeFormat(locale, { style, numeric }).format(
		Math.round(seconds / TIME_IN_SECONDS[unit]),
		unit,
	) as string;
}
