import { createContext } from 'react';

import type { Session } from '@change-corgi/core/session';

export type PrefetchedData = Readonly<Record<string, unknown>> | undefined;
export type PrefetchedUserData = Readonly<Record<string, string | number | boolean | undefined>> | undefined;

export type PrefetchContextData = Readonly<Record<string, PrefetchedData>> | undefined;
export type PrefetchUserContextData = Readonly<Record<string, PrefetchedUserData>> | undefined;
export type PrefetchSessionContextData = Session | undefined;

export const PrefetchContext = createContext<PrefetchContextData>(undefined);
export const PrefetchUserContext = createContext<PrefetchUserContextData>(undefined);
export const PrefetchSessionContext = createContext<PrefetchSessionContextData>(undefined);
