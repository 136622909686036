import type { JSX } from 'react';

import type { DateFormat, DateType } from '@change-corgi/core/i18n';
import { ClientRender } from '@change-corgi/core/react/ssr/render';

import { useI18n } from '../misc';

type Props = Readonly<{
	date: number | string | Date;
	type: DateType;
	format: DateFormat;
	/**
	 * If present, will be used for the first render before being replaced with a client-localized version using `date`.
	 *
	 * This is mostly useful when server-side rendering a page with a server-side localized date (to avoid hydration errors)
	 * that we want to replace with a client-localized date.
	 *
	 * Uses ClientRender under the hood
	 */
	placeholder?: string;
}>;

/**
 * Localizes a date using the current locale
 *
 * @example
 * <LocalizeDate date={createdAt} type="datetime" format="short" />
 * // when server-side rendering a page with a server-side localized date
 * <LocalizeDate placeholder={createdAtLocalized} date={createdAt} type="datetime" format="short" />
 */
export function LocalizeDate({ date, type, format, placeholder }: Props): JSX.Element {
	const { localizeDate } = useI18n();

	if (placeholder) {
		return <ClientRender placeholder={placeholder}>{() => localizeDate(date, type, format)}</ClientRender>;
	}

	return <>{localizeDate(date, type, format)}</>;
}
