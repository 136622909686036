import type { JSX } from 'react';

import type { RelativeTimeOptions } from '@change-corgi/core/i18n';
import { ClientRender } from '@change-corgi/core/react/ssr/render';

import { useI18n } from '../misc';

type Props = Readonly<{
	from: number | string | Date;
	to: number | string | Date;
	options?: RelativeTimeOptions;
	/**
	 * If present, will be used for the first render before being replaced with a client-localized version using `from` and `to`.
	 *
	 * This is mostly useful when server-side rendering a page with a server-side localized relative time (to avoid hydration errors)
	 * that we want to replace with a client-localized time (that might also update over time).
	 *
	 * Uses ClientRender under the hood
	 */
	placeholder?: string;
}>;
/**
 * Localizes a relative time using the current locale
 *
 * @example
 * <LocalizeRelativeTime from={createdAt} to={now} />
 * // when server-side rendering a page with a server-side localized date
 * <LocalizeRelativeTime placeholder={createdAtRelativeLocalized} from={createdAt} to={now} />
 */
export function LocalizeRelativeTime({ from, to, options, placeholder }: Props): JSX.Element {
	const { localizeRelativeTime } = useI18n();

	if (placeholder) {
		return <ClientRender placeholder={placeholder}>{() => localizeRelativeTime(from, to, options)}</ClientRender>;
	}

	return <>{localizeRelativeTime(from, to, options)}</>;
}
