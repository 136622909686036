import type { DateFormat, DateOptions, DateType } from '../shared/types';

import { DATE_FORMATS } from './dateFormats';

function toDate(date: string | number | Date): Date {
	if (date instanceof Date) {
		return date;
	}
	return new Date(date);
}

// eslint-disable-next-line max-params
export function localizeDate(
	locale: string,
	date: string | number | Date,
	dateType: DateType,
	dateFormat: DateFormat,
	options?: DateOptions,
): string {
	return new Intl.DateTimeFormat(locale, {
		...DATE_FORMATS[dateType][dateFormat],
		...options,
	}).format(toDate(date));
}
