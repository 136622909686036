import { isSafariStack } from './isSafariStack';

/**
 * Remove the n first lines of the stack of an error without changing the first line containing the type and message
 */
export function trimStack<ERR extends Error = Error>(err: ERR, count: number): ERR {
	if (count <= 0) return err;

	const lines = err.stack?.split('\n') || [];
	const firstLine = lines[0];

	if (isSafariStack(err)) {
		// weird Safari stacktrace that doesn't contain the error message
		const body = lines.slice(count).join('\n');

		// eslint-disable-next-line no-param-reassign
		err.stack = body;
	} else {
		const body = lines.slice(count + 1).join('\n');

		// eslint-disable-next-line no-param-reassign
		err.stack = body ? `${firstLine}\n${body}` : firstLine;
	}

	return err;
}
