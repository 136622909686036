import type { Replacements, TranslationKeyArg, Translations } from '../shared/types';
import type { UsedTranslationsTracker } from '../UsedTranslationsTracker';

import { getPluralKey } from './pluralKey';
import { translate } from './translate';

export function translatePlural(
	{
		locale,
		translations,
		tracker,
	}: {
		locale: string;
		translations: readonly Translations[];
		tracker?: UsedTranslationsTracker;
	},
	key: TranslationKeyArg,
	count: number,
	replacements: Replacements | undefined,
): string {
	return translate({ translations, tracker }, getPluralKey(locale, key, count), replacements);
}
