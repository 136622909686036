import type { FcmUtils, Normalizer } from '@change-corgi/core/fcm';

import { normalizeSafelist } from './normalize';

export type Options = Readonly<{
	getFeatureConfig: FcmUtils['get'];
}>;

export type Config = Readonly<{
	optimizelyEnabled: boolean;
	eventSafelist: readonly string[];
	propertySafelist: readonly string[];
}>;

const FCM_ENABLED_KEY = 'optimizely_enabled';
const FCM_EVENT_SAFELIST_KEY = 'optimizely_event_safelist';
const FCM_PROPERTY_SAFELIST_KEY = 'optimizely_property_safelist';

// TODO extract FCMs to corgi and pass the config in the utility's constructor
// Until then, we need these functions which are duplicates of the ones from @change/config/fcm/normalizers
const normalizeString: Normalizer<string> = (value: unknown) => {
	if (typeof value !== 'string') {
		throw new Error();
	}
	if (/^\s*$/.exec(value)) {
		throw new Error();
	}
	return value;
};
const normalizeBoolean: Normalizer<boolean> = (value: unknown) => {
	if (typeof value !== 'boolean') {
		throw new Error();
	}
	return value;
};
const normalizeJson: Normalizer<unknown> = (value: unknown) => {
	if (typeof value === 'boolean' || typeof value === 'number') {
		return value;
	}
	const str = normalizeString(value);
	try {
		return JSON.parse(str);
	} catch (e) {
		throw new Error(`Invalid JSON value: "${str}"`);
	}
};
const createJsonNormalizer = <T>(normalizeParsedValue: (parsedValue: unknown) => T): Normalizer<T> => {
	const fn = (value: unknown) => normalizeParsedValue(normalizeJson(value));
	// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access
	(fn as any).type = 'json';
	return fn;
};

export async function getConfig({ getFeatureConfig }: Options): Promise<Config> {
	return getFeatureConfig({
		optimizelyEnabled: {
			name: FCM_ENABLED_KEY,
			normalizer: normalizeBoolean,
			defaultValue: false,
		},
		eventSafelist: {
			name: FCM_EVENT_SAFELIST_KEY,
			normalizer: createJsonNormalizer(normalizeSafelist),
			defaultValue: [] as string[],
		},
		propertySafelist: {
			name: FCM_PROPERTY_SAFELIST_KEY,
			normalizer: createJsonNormalizer(normalizeSafelist),
			defaultValue: [] as string[],
		},
	});
}
