import qs from 'qs';

import type { ReportableError } from '@change-corgi/core/errorReporter/common';
import type { HttpClient } from '@change-corgi/core/http';

const reportCsrfError = (
	reportError: (error: ReportableError) => void,
	error: string,
	{
		message,
		url,
		requestId,
		status,
		cause,
	}: {
		message?: string;
		status?: number;
		url: string;
		requestId?: string;
		cause?: unknown;
	},
) => {
	reportError({
		error: new Error(error, { cause }),
		params: {
			message,
			status,
			url,
		},
		context: {
			requestId,
			response: {
				message,
			},
		},
	});
};

export type Options = Readonly<{
	fetch: HttpClient['fetch'];
	reportError: (error: ReportableError) => void;
}>;

export async function getCsrfToken({ fetch, reportError }: Options): Promise<string> {
	// using a cache buster to ensure that we're always getting the latest token
	// even if the cache headers are being removed by a reverse proxy
	const csrfUrl = `/api-proxy/-/csrf-token${qs.stringify({ cb: Date.now() }, { addQueryPrefix: true })}`;

	try {
		const response = await fetch(csrfUrl);

		const requestId = response.headers.get('x-request-id') || undefined;
		if (!response.ok) {
			reportCsrfError(
				reportError,
				'Failed to call CSRF token API',
				response.status
					? {
							message: response.statusText,
							status: response.status,
							url: csrfUrl,
							requestId,
						}
					: {
							// eslint-disable-next-line @typescript-eslint/no-base-to-string
							message: response.toString(),
							url: csrfUrl,
							requestId,
						},
			);
			return '';
		}
		const token = response.headers.get('x-csrf-token');
		if (!token) {
			reportCsrfError(reportError, 'CSRF token header not found', {
				url: csrfUrl,
				requestId,
			});
			return '';
		}
		return token;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (response: any) {
		reportCsrfError(reportError, 'Failed to call CSRF token API', {
			// eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment
			message: response.toString(),
			url: csrfUrl,
			cause: response,
		});
		return '';
	}
}
