/* eslint-disable @typescript-eslint/no-explicit-any */
import type { ComponentProps, JSX, JSXElementConstructor } from 'react';

import baseLoadable from '@loadable/component';
import type { LoadableComponent } from '@loadable/component';
import omit from 'lodash/fp/omit';

export type LoadableOptionsWithExportName<EXPORT_NAME extends string> = {
	fallback?: JSX.Element;
	ssr?: boolean;
	exportName: EXPORT_NAME;
};

export type ExtendedLoadableComponent<PROPS> = LoadableComponent<PROPS> & {
	exportName: string;
};

export function loadable<EXPORT_NAME extends string, MOD extends Record<EXPORT_NAME, JSXElementConstructor<any>>>(
	loadFn: () => Promise<MOD>,
	options: LoadableOptionsWithExportName<EXPORT_NAME>,
): ExtendedLoadableComponent<ComponentProps<MOD[EXPORT_NAME]>> {
	// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
	const res: ExtendedLoadableComponent<any> = baseLoadable(loadFn, {
		ssr: true,
		/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion, @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access */
		resolveComponent: (module: any) => module[options.exportName],
		...omit('exportName', options),
	}) as any;
	res.exportName = options.exportName;
	// eslint-disable-next-line @typescript-eslint/no-unsafe-return
	return res as any;
}
