import { isSsr } from '@change-corgi/core/ssr';
import { getWindow } from '@change-corgi/core/window';

export function isOnline(): boolean {
	if (isSsr()) return true;
	const { navigator } = getWindow();

	// just in case the browser doesn't support navigator.onLine
	if (!('onLine' in navigator)) return true;

	return navigator.onLine;
}
