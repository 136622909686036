/* eslint-disable @typescript-eslint/naming-convention */
import { createMandatoryContext } from '@change-corgi/core/react/context';
import type { ResultMandatory } from '@change-corgi/core/react/context';

import type { Utilities, UtilityContext as UtilityContextType } from './types';
import { createUtilityContext } from './types';

type MandatoryContextResult = ResultMandatory<
	UtilityContextType & { __internal_originalUtilities: Utilities },
	{ utilities: Utilities }
>;

const result: MandatoryContextResult = createMandatoryContext<
	UtilityContextType & { __internal_originalUtilities: Utilities },
	{ utilities: Utilities }
>(undefined, {
	name: 'UtilityContext',
	processProviderProps: ({ utilities }) => ({
		...createUtilityContext(utilities),
		__internal_originalUtilities: utilities,
	}),
});

// doing this instead of destructuring and type inference to ensure the generated types
// reference UtilityContextType instead of inlining it (which would remove the @deprecated comments)
export const UtilityContext: MandatoryContextResult['Context'] = result.Context;
export const useUtilityContext: MandatoryContextResult['useContext'] = result.useContext;
export const UtilityContextProvider: MandatoryContextResult['Provider'] = result.Provider;
