import type { JSX } from 'react';

import type { CurrencyOptions } from '@change-corgi/core/i18n';

import { useI18n } from '../misc';

type Props = Readonly<{
	currency: string;
	value: number;
	options?: CurrencyOptions;
}>;

export function LocalizeCurrency({ value, currency, options }: Props): JSX.Element {
	const { localizeCurrency } = useI18n();

	return <>{localizeCurrency(value, currency, options)}</>;
}
