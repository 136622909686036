import { MissingReplacementsError } from '../shared/errors';
import type { Replacements, TranslationKeyArg } from '../shared/types';

export function replace(key: TranslationKeyArg, translation: string, replacements: Replacements | undefined): string {
	const replaceFailures: string[] = [];
	const result = translation.replace(/%\{([^{}]+)}/g, (m, name: string) => {
		if (!replacements || !(name in replacements)) {
			replaceFailures.push(name);
			return m;
		}
		return replacements?.[name].toString();
	});
	if (replaceFailures.length) {
		throw new MissingReplacementsError({
			key: key as string,
			translation,
			finalTranslation: result,
			missingReplacements: replaceFailures,
		});
	}
	return result;
}
