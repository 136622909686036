import mapKeys from 'lodash/mapKeys';

import type { ReportableError } from '@change-corgi/core/errorReporter/common';
import { getWindow } from '@change-corgi/core/window';

import { wrapFetch } from './fetch';
import { resolveInput } from './resolve';
import { isSendBeaconSupported } from './support';

type Options = Readonly<{
	headers?: Readonly<Record<string, string>>;
	fetch?: typeof fetch;
	/**
	 * allows to set an origin for prefixing absolute urls
	 * where that origin cannot be automatically determined (e.g. on server)
	 */
	origin?: string;
	reportNetworkError: (error: ReportableError) => void;
}>;

/* eslint-disable @typescript-eslint/consistent-type-definitions, @typescript-eslint/method-signature-style */
interface HttpClient {
	fetch(input: RequestInfo, init?: RequestInit): Promise<Response>;
	request<RES = unknown>(input: RequestInfo, init?: RequestInit): Promise<RES>;
	getAsset<RES = unknown>(input: RequestInfo, init?: RequestInit): Promise<RES>;
	get<RES = unknown>(input: RequestInfo, init?: RequestInit): Promise<RES>;
	head(input: RequestInfo, init?: RequestInit): Promise<void>;
	post<RES = unknown>(input: RequestInfo, body?: unknown, init?: RequestInit): Promise<RES>;
	put<RES = unknown>(input: RequestInfo, body?: unknown, init?: RequestInit): Promise<RES>;

	patch<RES = unknown>(input: RequestInfo, body?: unknown, init?: RequestInit): Promise<RES>;
	delete<RES = unknown>(input: RequestInfo, init?: RequestInit): Promise<RES>;
	/**
	 * make sure to send the data event if the user navigates outside the page
	 * @see https://developer.mozilla.org/en-US/docs/Web/API/Navigator/sendBeacon
	 */
	sendBeacon(url: string, body?: unknown): Promise<boolean>;
}
/* eslint-enable @typescript-eslint/consistent-type-definitions, @typescript-eslint/method-signature-style */

class HttpClientImpl implements HttpClient {
	private readonly headers?: Options['headers'];
	private readonly _fetch: typeof fetch;
	private readonly origin?: Options['origin'];

	constructor({ headers, fetch: fetchOverride, origin, reportNetworkError }: Options) {
		this._fetch = wrapFetch({ fetch: fetchOverride || fetch, reportNetworkError });
		this.headers = toLowerKeys(headers);
		this.origin = origin;
	}

	async fetch(input: RequestInfo, init: RequestInit = {}): Promise<Response> {
		return this._fetch(resolveInput(input, this.origin), {
			...init,
			credentials: 'same-origin',
			headers: {
				// FIXME handle [string, string][]
				...toLowerKeys(init.headers as Readonly<Record<string, string>>),
				// eslint-disable-next-line @typescript-eslint/naming-convention
				'x-requested-with': init.keepalive ? 'fetch (keepalive)' : 'fetch',
				...this.headers,
			},
		});
	}

	async request<RES = unknown>(input: RequestInfo, init: RequestInit = {}): Promise<RES> {
		return (
			this._fetch(resolveInput(input, this.origin), {
				...init,
				credentials: 'same-origin',
				headers: {
					// FIXME handle [string, string][]
					...toLowerKeys(init.headers as Readonly<Record<string, string>>),
					// eslint-disable-next-line @typescript-eslint/naming-convention
					'x-requested-with': init.keepalive ? 'fetch (keepalive)' : 'fetch',
					...this.headers,
				},
			})
				// eslint-disable-next-line promise/prefer-await-to-then
				.then(async (response) => {
					if (response.ok) {
						// Check for empty responses, as response.json() will fail on JSON parsing otherwise.
						const json = await response.text();
						return (json ? JSON.parse(json) : undefined) as Promise<RES>;
					}
					// FIXME throw error object
					// eslint-disable-next-line @typescript-eslint/only-throw-error
					throw response;
				})
		);
	}

	async getAsset<RES = unknown>(input: RequestInfo, init: RequestInit = {}): Promise<RES> {
		return (
			this._fetch(resolveInput(input, this.origin), {
				...init,
				headers: {
					// FIXME handle [string, string][]
					...toLowerKeys(init.headers as Readonly<Record<string, string>>),
					...this.headers,
				},
			})
				// eslint-disable-next-line promise/prefer-await-to-then, @typescript-eslint/prefer-promise-reject-errors
				.then(async (response) => (response.ok ? (response.json() as Promise<RES>) : Promise.reject(response)))
		);
	}

	async get<RES = unknown>(input: RequestInfo, init: RequestInit = {}): Promise<RES> {
		return this.request<RES>(input, {
			...init,
			method: 'GET',
		});
	}

	async head(input: RequestInfo, init: RequestInit = {}): Promise<void> {
		return this.request<undefined>(input, {
			...init,
			method: 'HEAD',
		});
	}

	private async sendBody<RES = unknown>(input: RequestInfo, body: unknown, init: RequestInit) {
		return this.request<RES>(input, {
			...init,
			body: JSON.stringify(body),
			headers: {
				// eslint-disable-next-line @typescript-eslint/naming-convention
				'Content-Type': 'application/json',
				...init.headers,
				...this.headers,
			},
		});
	}

	async post<RES = unknown>(input: RequestInfo, body: unknown = undefined, init: RequestInit = {}): Promise<RES> {
		return this.sendBody<RES>(input, body, { ...init, method: 'POST' });
	}

	async put<RES = unknown>(input: RequestInfo, body: unknown = undefined, init: RequestInit = {}): Promise<RES> {
		return this.sendBody<RES>(input, body, { ...init, method: 'PUT' });
	}

	async patch<RES = unknown>(input: RequestInfo, body: unknown = undefined, init: RequestInit = {}): Promise<RES> {
		return this.sendBody<RES>(input, body, { ...init, method: 'PATCH' });
	}

	async delete<RES = unknown>(input: RequestInfo, init: RequestInit = {}): Promise<RES> {
		return this.request<RES>(input, { ...init, method: 'DELETE' });
	}

	async sendBeacon(url: string, body: unknown = undefined): Promise<boolean> {
		if (isSendBeaconSupported()) {
			try {
				return !!getWindow().navigator.sendBeacon(url, JSON.stringify(body));
			} catch (e) {
				// what appear to be bots are apparently messing with the native sendBeacon function
				// which result in "Illegal invocation" errors being reported in high numbers
				// => let's just ignore those
				return false;
			}
		}

		// older browsers (IE11...)
		return (
			this.post(url, body)
				// eslint-disable-next-line promise/prefer-await-to-then
				.then(
					() => true,
					() => false,
				)
		);
	}
}

export type { HttpClient };

export function createHttpClient(options: Options): HttpClient {
	return new HttpClientImpl(options);
}

export function createHttpClientFake(errorMsg: string): HttpClient {
	const errorFn = () => {
		throw new Error(errorMsg);
	};
	return {
		delete: errorFn,
		fetch: errorFn,
		get: errorFn,
		head: errorFn,
		getAsset: errorFn,
		patch: errorFn,
		post: errorFn,
		put: errorFn,
		request: errorFn,
		sendBeacon: errorFn,
	};
}

const toLowerKeys = (obj: Readonly<Record<string, string>> | undefined) =>
	obj ? mapKeys(obj, (_v, k) => k.toLowerCase()) : undefined;
