export function isZeroDecimalCurrency(currency: string): boolean {
	return [
		'bif', // Burundian Franc
		'clp', // Chilean Peso
		'djf', // Djiboutian Franc
		'gnf', // Guinean Franc
		'jpy', // Japanese Yen
		'kmf', // Comorian Franc
		'krw', // South Korean Won
		'mga', // Malagasy Ariary
		'pyg', // Paraguayan Guaraní
		'rwf', // Rwandan Franc
		'vnd', // Vietnamese Đồng
		'vuv', // Vanuatu Vatu
		'xaf', // Central African Cfa Franc
		'xof', // West African Cfa Franc
		'xpf', // Cfp Franc
	].includes(currency.toLowerCase());
}
