const CHANGE_HOSTNAMES: RegExp[] = [
	/^change\.org$/,
	/\.change\.org$/,
	/^www\.staging-change\.org$/,
	/^www\.[^/]+\.changeeng\.org$/,
	/^changefoundation\.org$/,
	/\.changefoundation\.org$/,
	...(process.env.NODE_ENV === 'development' ? [/^www\.local-change\.org$/] : []),
];

export function isChangeHostname(hostname: string): boolean {
	return CHANGE_HOSTNAMES.some((pattern: RegExp) => !!pattern.exec(hostname));
}
