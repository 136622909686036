import { isbot as isBot } from 'isbot';

import type { EventTrackingDecorator } from '@change-corgi/core/eventTracker';

import type { WebviewType } from './computeWebviewType';
import { computeWebviewType } from './computeWebviewType';
import type { ParsedUserAgent } from './parse';
import { parseUserAgent } from './parse';

type Options = Readonly<{ userAgent: string }>;

/* eslint-disable @typescript-eslint/consistent-type-definitions, @typescript-eslint/method-signature-style */
interface UserAgentUtils extends EventTrackingDecorator {
	readonly isWebview: boolean;
	getWebviewType(): WebviewType;
	getParsedUserAgent(): ParsedUserAgent;
	getUserAgent(): string;
	isBot(): boolean;
}
/* eslint-enable @typescript-eslint/consistent-type-definitions, @typescript-eslint/method-signature-style */

class UserAgentUtilsImpl implements UserAgentUtils {
	private readonly userAgent: string;
	private readonly parsedUserAgent: ParsedUserAgent;
	private readonly webviewType: WebviewType;
	private readonly _isBot: boolean;

	constructor({ userAgent }: Options) {
		this.userAgent = userAgent;
		this.parsedUserAgent = parseUserAgent(this.userAgent);
		this.webviewType = computeWebviewType(this.userAgent);
		this._isBot = isBot(this.userAgent);
	}

	get isWebview(): boolean {
		return this.getWebviewType() !== 'unknown';
	}

	getWebviewType(): WebviewType {
		return this.webviewType;
	}

	getParsedUserAgent(): ParsedUserAgent {
		return this.parsedUserAgent;
	}

	getUserAgent(): string {
		return this.userAgent;
	}

	isBot(): boolean {
		return this._isBot;
	}

	getEventTrackingData(): Readonly<Record<string, unknown>> {
		const parsedUserAgent = this.getParsedUserAgent();

		return {
			mobile: ['mobile', 'tablet'].includes(parsedUserAgent.getDevice().type || ''),
			platform: parsedUserAgent.getOS().name,
			browser: parsedUserAgent.getBrowser().name,
			browser_version: parsedUserAgent.getBrowser().version,
			os: parsedUserAgent.getOS().name,
			os_version: parsedUserAgent.getOS().version,
			user_agent: parsedUserAgent.getUA(),

			webview: this.getWebviewType(),
		};
	}
}

export type { UserAgentUtils };

export function createUserAgentUtils(options: Options): UserAgentUtils {
	return new UserAgentUtilsImpl(options);
}

export function createUserAgentUtilsFake(errorMsg: string): UserAgentUtils {
	const errorFn = () => {
		throw new Error(errorMsg);
	};
	return {
		getEventTrackingData: errorFn,
		getParsedUserAgent: errorFn,
		getUserAgent: errorFn,
		getWebviewType: errorFn,
		isBot: errorFn,
		get isWebview() {
			return errorFn();
		},
	};
}
