import { useEffect, useState } from 'react';
import type { JSX } from 'react';

import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router';

import { useUtilityContext } from '@change-corgi/core/react/utilityContext';

import { isOptimizelyEnabled } from './enabled';

type Props = Readonly<{
	allowed: boolean;
	trackingAllowed?: boolean;
	locationMatch?: readonly RegExp[];
}>;

export function Optimizely({ allowed, trackingAllowed, locationMatch }: Props): JSX.Element | null {
	const { pathname } = useLocation();

	// initialize for first render for SSR support
	const [enabled, setEnabled] = useState(isOptimizelyEnabled({ allowed, locationMatch, pathname }));

	const { optimizely } = useUtilityContext();

	useEffect(() => {
		// once it has been enabled once, leave it enabled
		setEnabled((alreadyEnabled) => alreadyEnabled || isOptimizelyEnabled({ allowed, locationMatch, pathname }));
	}, [setEnabled, allowed, locationMatch, pathname]);

	useEffect(() => {
		if (enabled) {
			optimizely.setState(trackingAllowed ? 'enabled' : 'opted-out');
		} else {
			optimizely.setState('disabled');
		}
	}, [optimizely, enabled, trackingAllowed]);

	if (!enabled) return null;

	const { init, tracking, scriptUrl, onOptimizelyLoad } = optimizely.getScript(!!trackingAllowed);

	return (
		<Helmet>
			<script>{init}</script>
			<script>{tracking}</script>
			{/* the additional attribute forces a reload when the tracking config changes */}
			<script src={scriptUrl} data-opt-in={!!trackingAllowed} />
			{/* this allows to detect a load/reload of optimizely */}
			<script>{onOptimizelyLoad}</script>
		</Helmet>
	);
}
