/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import type { QueryClient } from '@tanstack/react-query';
import hoistStatics from 'hoist-non-react-statics';

import type { ExtendedLoadableComponent } from '@change-corgi/core/react/loadable';
import type { UtilityContext } from '@change-corgi/core/react/utilityContext';
import type { Session } from '@change-corgi/core/session';
import type { EmptyIntersection, EmptyObject } from '@change-corgi/core/types';

import type { PrefetchedUserData } from './context';
import { usePrefetchedData, usePrefetchedSession, usePrefetchedUserData } from './hook';

// eslint-disable-next-line @typescript-eslint/consistent-indexed-object-style
type ParsedQs = { [key: string]: undefined | string | string[] | ParsedQs | ParsedQs[] };

export type PrefetchContext<CACHE = any> = Readonly<{
	utilityContext: UtilityContext;
	l10n: Readonly<{ locale: string; countryCode: string }>;
	/**
	 * app-wide cached data that should be retrieved by prefetching and used during hydration
	 */
	cache: CACHE;
	path: string;
	params: Readonly<Record<string, string | undefined>>;
	query: ParsedQs;
	queryClient: QueryClient;
	/**
	 * session only available if server-rendering with user data (=> no caching)
	 */
	session?: Session;
	/**
	 * comes from prefetchUserData()
	 */
	getUserData: () => Promise<PrefetchedUserData | undefined>;
}>;
export type PrefetchUserContext = Readonly<{
	utilityContext: UtilityContext;
	l10n: Readonly<{ locale: string; countryCode: string }>;
	path: string;
	params: Readonly<Record<string, string | undefined>>;
	query: ParsedQs;
	session: Session;
}>;
export type PrefetchDependenciesContext = Readonly<{
	l10n: Readonly<{ locale: string; countryCode: string }>;
	path: string;
	params: Readonly<Record<string, string | undefined>>;
	query: ParsedQs;
	/**
	 * session only available if server-rendering with user data (=> no caching)
	 */
	session?: Session;
	/**
	 * comes from prefetchUserData()
	 */
	getUserData: () => Promise<PrefetchedUserData | undefined>;
}>;

export type PrefetchDataFunction<D = any> = (context: PrefetchContext) => Promise<D>;
export type PrefetchDataFunctionVoid = (context: PrefetchContext) => Promise<void>;
export type PrefetchUserDataFunction<D = any> = (context: PrefetchUserContext) => Promise<D>;
export type PrefetchUserDataFunctionVoid = (context: PrefetchUserContext) => Promise<void>;
export type PrefetchedSessionProps = { prefetchedSession?: Session };
export type PrefetchedDataProps<D = any> = { prefetchedData?: D };
export type PrefetchedUserDataProps<D = any> = { prefetchedUserData?: D };
export type EmptyPrefetchedDataProps = { prefetchedData?: EmptyObject };
export type EmptyPrefetchedUserDataProps = { prefetchedUserData?: EmptyObject };
export type PrefetchDependency<PROPS = any> = PrefetchableComponentType<PROPS> &
	(EmptyIntersection | ExtendedLoadableComponent<PROPS>);
type StaticsBase = Readonly<{
	/**
	 * name to be used to distinguish fetched data from other components
	 */
	prefetchName: string;
	/**
	 * components for which prefetching must be done as well
	 */
	prefetchDependencies?: (
		context: PrefetchDependenciesContext,
	) => readonly PrefetchDependency[] | Promise<readonly PrefetchDependency[]>;
}>;
type WithPrefetchDataStatics<D = any> = Readonly<{
	prefetchData: PrefetchDataFunction<D>;
}>;
type WithoutPrefetchDataStatics = Readonly<{
	// this is to allow for conditional inference
	prefetchData?: PrefetchDataFunctionVoid;
}>;
type WithPrefetchUserDataStatics<D = any> = Readonly<{
	prefetchUserData: PrefetchUserDataFunction<D>;
}>;
type WithoutPrefetchUserDataStatics = Readonly<{
	// this is to allow for conditional inference
	prefetchUserData?: PrefetchUserDataFunctionVoid;
}>;

type OptionsBase = Readonly<{
	/**
	 * name to be used to distinguish fetched data from other components
	 */
	prefetchName: string;
	/**
	 * components for which prefetching must be done as well
	 */
	prefetchDependencies?:
		| readonly PrefetchDependency[]
		| ((
				context: PrefetchDependenciesContext,
		  ) => readonly PrefetchDependency[] | Promise<readonly PrefetchDependency[]>);
}>;

export function withPrefetchedData<
	PROPS,
	OPTS extends OptionsBase &
		(PROPS extends PrefetchedDataProps<infer DATA>
			? {
					prefetchData: PrefetchDataFunction<DATA>;
				}
			: { prefetchData?: PrefetchDataFunctionVoid }) &
		(PROPS extends PrefetchedUserDataProps<infer USER_DATA>
			? {
					prefetchUserData: PrefetchUserDataFunction<USER_DATA>;
				}
			: { prefetchUserData?: PrefetchUserDataFunctionVoid }),
>(
	component: React.ComponentType<PROPS>,
	options: OPTS,
): React.ComponentClass<
	Omit<PROPS, keyof PrefetchedDataProps<any> | keyof PrefetchedUserDataProps<any> | keyof PrefetchedSessionProps>
> &
	StaticsBase &
	(OPTS extends { prefetchData: infer FN } ? { prefetchData: FN } : EmptyIntersection) &
	(OPTS extends { prefetchUserData: infer FN } ? { prefetchUserData: FN } : EmptyIntersection) {
	const displayName = `withPrefetchedData(${component.displayName || component.name})`;

	function WrappedComponent(props: any) {
		const Component = component as any;
		const prefetchedData = usePrefetchedData();
		const prefetchedUserData = usePrefetchedUserData();
		const prefetchedSession = usePrefetchedSession();

		return (
			<Component
				prefetchedData={prefetchedData?.[options.prefetchName]}
				prefetchedUserData={prefetchedUserData?.[options.prefetchName]}
				prefetchedSession={prefetchedSession}
				{...props}
			/>
		);
	}

	WrappedComponent.prefetchName = options.prefetchName;
	WrappedComponent.prefetchData = options.prefetchData && options.prefetchData.bind(options);
	WrappedComponent.prefetchUserData = options.prefetchUserData && options.prefetchUserData.bind(options);
	WrappedComponent.prefetchDependencies = Array.isArray(options.prefetchDependencies)
		? () => options.prefetchDependencies
		: options.prefetchDependencies;
	WrappedComponent.displayName = displayName;
	WrappedComponent.WrappedComponent = component;

	return hoistStatics(WrappedComponent as any, component);
}

export type PrefetchableComponentType<P = any> =
	| (React.ComponentType<P> &
			Readonly<{
				// this is to allow for conditional inference
				prefetchName?: undefined;
			}>)
	| (React.ComponentType<
			Omit<P, keyof PrefetchedDataProps<any> | keyof PrefetchedUserDataProps<any> | keyof PrefetchedSessionProps>
	  > &
			StaticsBase &
			(WithPrefetchDataStatics | WithoutPrefetchDataStatics) &
			(WithPrefetchUserDataStatics | WithoutPrefetchUserDataStatics));

function createPrefetchState<D extends Record<string, any>>(
	id: number | string,
	status: 'loaded',
	data: D,
): Readonly<D & { __prefetchId: number | string; status: 'loaded' }>;
function createPrefetchState<D extends Record<string, any> = EmptyIntersection>(
	id: number | string,
	status: 'error',
	data?: Record<string, any>,
): Readonly<D & { __prefetchId: number | string; status: 'error' }>;
function createPrefetchState(id: number | string, status: 'loaded' | 'error', data?: Record<string, any>): any {
	return { __prefetchId: id, status, ...data };
}

export { createPrefetchState };
