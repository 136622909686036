import type { Environment } from './types';

// currently no other way to retrieve environment
export function detectEnvironment(hostname: string, buildEnvironment: Environment): Environment {
	if (['development'].includes(buildEnvironment)) {
		return buildEnvironment;
	}
	switch (hostname) {
		case 'www.change.org':
			return 'production';
		case 'www.staging-change.org':
			return 'staging';
		default:
			return hostname.endsWith('.changeeng.org') ? 'demo' : 'development';
	}
}
