import { useAppRenderContext } from './context';

export function useIsFirstAppRender(): boolean {
	const context = useAppRenderContext();

	if (!context) {
		return false;
	}

	return context.firstRender;
}
