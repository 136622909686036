export function getCurrencySymbolPosition(locale: string, currencyArg: string): 'before' | 'after' {
	const currency = currencyArg.toUpperCase();
	const formatter = new Intl.NumberFormat(locale, {
		style: 'currency',
		currency,
		currencyDisplay: 'code',
	});
	const parts = formatter.formatToParts(99); // number doesn't matter here
	const currencyIndex = parts.findIndex((part) => part.value === currency);

	return currencyIndex === 0 ? 'before' : 'after';
}
