/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMemo } from 'react';

import hoistStatics from 'hoist-non-react-statics';

import type { Translations } from '@change-corgi/core/i18n';
import {
	createUtilityContextI18n,
	__internal_useUtilityContext as useUtilityContext,
	__internal_UtilityContext as UtilityContext,
} from '@change-corgi/core/react/utilityContext';

import { generateAdditionalPlurals } from './generateAdditionalPlurals';

export function withI18nPlaceholders<C extends React.ComponentType<any>>(
	translations: Translations | readonly Translations[],
	component: C,
): C {
	const displayName = `withI18nPlaceholders(${component.displayName || component.name})`;

	// adding non-en-US plurals by reusing the .other en-US plural, to avoid missing translations
	// in locales using different plural keys
	const allTranslations: readonly Translations[] = [
		...(Array.isArray(translations) ? translations : [translations]),
		generateAdditionalPlurals(translations),
	];

	function WrappedComponent(props: any) {
		const Component = component as any;
		const utilityContext = useUtilityContext();

		const newUtilityContext = useMemo(() => {
			const i18n = utilityContext.__internal_originalUtilities.i18n.extends({
				additionalTranslations: allTranslations,
			});
			return {
				...utilityContext,
				i18n: createUtilityContextI18n(i18n),
				__internal_originalUtilities: {
					...utilityContext.__internal_originalUtilities,
					i18n,
				},
			};
		}, [utilityContext]);

		return (
			<UtilityContext.Provider value={newUtilityContext}>
				<Component {...props} />
			</UtilityContext.Provider>
		);
	}

	WrappedComponent.displayName = displayName;
	WrappedComponent.WrappedComponent = component;

	return hoistStatics(WrappedComponent as any, component);
}
