import type { ReportableError } from '@change-corgi/core/errorReporter/common';
import { isSsr } from '@change-corgi/core/ssr';

import { initSdk, lookupSdkHash } from './initSdk';
import { getFacebookLocale } from './locales';

export type Options = Readonly<{
	appId: string;
	/**
	 * locale in xx-YY format
	 */
	locale: string;
	reportError: (error: ReportableError) => void;
}>;

/* eslint-disable @typescript-eslint/consistent-type-definitions, @typescript-eslint/method-signature-style */
interface Facebook {
	readonly apiVersion: string;
	readonly authStatus: fb.StatusResponse['status'] | undefined;
	readonly authResponse: fb.StatusResponse['authResponse'] | undefined;
	readonly isAuthenticated: boolean;
	readonly isConnected: boolean;
	readonly sdkHash: string | undefined;
	/**
	 * locale in FB format (xx_YY)
	 */
	readonly locale: string;
	/**
	 * preloads the SDK (based on cookie prefs for instance),
	 * otherwise SDK load will be triggered by ui() calls
	 */
	preload(): this;
	load(): Promise<void>;
	login(params?: fb.LoginOptions): Promise<fb.StatusResponse>;
	ui(params: fb.ShareDialogParams): Promise<(fb.ShareDialogResponse & { cancelled: false }) | { cancelled: true }>;
}
/* eslint-enable @typescript-eslint/consistent-type-definitions, @typescript-eslint/method-signature-style */

class FacebookImpl implements Facebook {
	private readonly appId: string;
	private _locale: string;
	private fbPromise: Promise<fb.FacebookStatic | undefined> | undefined;
	private _authStatus: fb.StatusResponse | undefined;
	readonly apiVersion: string = '19.0';
	private _sdkHash: string | undefined;
	private readonly reportError: (error: ReportableError) => void;

	constructor({ reportError, appId, locale }: Options) {
		this.appId = appId;
		this._locale = locale;
		this.reportError = reportError;
	}

	private updateAuthStatus(authStatus: fb.StatusResponse) {
		this._authStatus = authStatus;
	}

	get authStatus(): fb.StatusResponse['status'] | undefined {
		return this._authStatus?.status;
	}

	get authResponse(): fb.StatusResponse['authResponse'] | undefined {
		return this._authStatus?.authResponse;
	}

	get isAuthenticated(): boolean {
		return !!this.authStatus && ['connected', 'not_authorized'].includes(this.authStatus);
	}

	get isConnected(): boolean {
		return !!this.authStatus && ['connected'].includes(this.authStatus);
	}

	get sdkHash(): string | undefined {
		return this._sdkHash;
	}

	get locale(): string {
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		return getFacebookLocale(this._locale) || getFacebookLocale('en-US')!;
	}

	preload(): this {
		this.init();
		return this;
	}

	private init(): void {
		if (isSsr()) {
			throw new Error('Facebook is not available server-side');
		}

		if (this.fbPromise) {
			return;
		}

		this.fbPromise = initSdk({
			apiVersion: this.apiVersion,
			locale: this.locale,
			appId: this.appId,
			reportError: this.reportError,
		})
			// eslint-disable-next-line promise/prefer-await-to-then
			.then(
				// eslint-disable-next-line @typescript-eslint/naming-convention
				async (FB) =>
					// eslint-disable-next-line promise/avoid-new
					new Promise<fb.FacebookStatic>((resolve) => {
						this._sdkHash = lookupSdkHash();
						FB.Event.subscribe('auth.authResponseChange', (response) => this.updateAuthStatus(response));
						FB.getLoginStatus((response) => {
							this.updateAuthStatus(response);
							resolve(FB);
						});
					}),
			)
			// eslint-disable-next-line promise/prefer-await-to-then
			.catch((e) => {
				// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
				this.reportError(new Error(`Could not initialize Facebook: ${e}`));
				return undefined;
			});
	}

	async load() {
		if (isSsr()) {
			throw new Error('Facebook is not available server-side');
		}

		this.init();

		await this.fbPromise;
	}

	async login(options?: fb.LoginOptions) {
		if (isSsr()) {
			throw new Error('Facebook is not available server-side');
		}

		this.init();

		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		const FB = await this.fbPromise!;

		/* istanbul ignore next */
		if (!FB) {
			throw new Error('Facebook was not properly initialized');
		}

		// eslint-disable-next-line promise/avoid-new
		return new Promise<fb.StatusResponse>((resolve) => {
			FB.login((response) => {
				resolve(response);
			}, options);
		});
	}

	// TODO add additional dialog types support
	async ui(
		params: fb.ShareDialogParams,
	): Promise<(fb.ShareDialogResponse & { cancelled: false }) | { cancelled: true }>;
	// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any, lines-between-class-members
	async ui(params: any) {
		if (isSsr()) {
			throw new Error('Facebook is not available server-side');
		}

		this.init();

		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		const FB = await this.fbPromise!;

		/* istanbul ignore next */
		if (!FB) {
			throw new Error('Facebook was not properly initialized');
		}

		// eslint-disable-next-line promise/avoid-new
		return new Promise<Readonly<{ postId?: string; cancelled: boolean }>>((resolve, reject) => {
			// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
			FB.ui(params, (response) => {
				if (response && response.error_code) {
					reject(new Error(`FB error: ${response.error_code}: ${response.error_message || ''}`));
					return;
				}
				const cancelled = response === undefined;
				resolve({ ...(response || {}), cancelled });
			});
		});
	}
}

export type { Facebook };

export function createFacebook(options: Options): Facebook {
	return new FacebookImpl(options);
}

export function createFacebookFake(errorMsg: string): Facebook {
	const errorFn = () => {
		throw new Error(errorMsg);
	};
	return {
		get apiVersion() {
			return errorFn();
		},
		get authStatus() {
			return errorFn();
		},
		get authResponse() {
			return errorFn();
		},
		get isAuthenticated() {
			return errorFn();
		},
		get isConnected() {
			return errorFn();
		},
		get locale() {
			return errorFn();
		},
		get sdkHash() {
			return errorFn();
		},
		preload: errorFn,
		load: errorFn,
		login: errorFn,
		ui: errorFn,
	};
}
