import { useEffect, useState } from 'react';

export function useNow(refreshInterval = 0): Date {
	const [now, setNow] = useState<Date>(new Date());

	useEffect(() => {
		if (refreshInterval <= 0) {
			return undefined;
		}

		const interval = window.setInterval(() => {
			try {
				setNow(new Date());
			} catch (e) {
				// catching error to avoid unlimited reported error
				// when rendering issue occurs
				// see https://changeorg.airbrake.io/projects/257900/groups/3125273061074847282/notices/3125273046527061976
			}
		}, refreshInterval);

		return () => {
			window.clearInterval(interval);
		};
	}, [refreshInterval]);

	return now;
}
