import { useEffect } from 'react';

import { usePageState } from './context';

type Options = Readonly<{
	persistent?: boolean;
}>;

export function useSetPageState(name: string, value: unknown, { persistent }: Options = {}): void {
	const pageState = usePageState();

	pageState.setValue(name, value);

	useEffect(() => {
		if (persistent) return undefined;

		// reset data if parent component re-renders
		const clear = pageState.setValue(name, value);

		// clear on unmount
		return clear;
	}, [pageState, name, value, persistent]);
}
