import { createContext } from '@change-corgi/core/react/context';

import type { TrackingFn } from './types';

export const {
	Context: TrackingContext,
	Provider: TrackingContextProvider,
	useContext: useTrackingContext,
} = createContext<TrackingFn | undefined>(undefined, {
	name: 'TrackingContext',
});
