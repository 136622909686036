// https://developer.mozilla.org/en-US/docs/Glossary/Base64#the_unicode_problem

function base64ToBytes(base64: string) {
	// eslint-disable-next-line no-restricted-syntax
	const binString = atob(base64);
	// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-argument
	return Uint8Array.from(binString as any, (m: any) => (m as string).codePointAt(0) as number);
}

function bytesToBase64(bytes: Uint8Array) {
	const binString = Array.from(bytes, (byte) => String.fromCodePoint(byte)).join('');
	// eslint-disable-next-line no-restricted-syntax
	return btoa(binString);
}

/**
 * Encodes text into base64. Supports Unicode characters.
 * Do not use for encoding files/images and dealing with data-urls.
 */
export function toBase64(str: string): string {
	return bytesToBase64(new TextEncoder().encode(str));
}

/**
 * Decodes text from base64. Supports Unicode characters.
 * Do not use for encoding files/images and dealing with data-urls.
 */
export function fromBase64(str: string): string {
	return new TextDecoder().decode(base64ToBytes(str));
}
