import { useUtilityContext } from '@change-corgi/core/react/utilityContext';

import { useTrackingContext } from './context';
import type { TrackingFn } from './types';

export function useTracking(): TrackingFn {
	const utilityContext = useUtilityContext();
	const context = useTrackingContext();

	// eslint-disable-next-line deprecation/deprecation
	return context || utilityContext.tracker.track;
}
