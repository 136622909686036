import { isSafariStack } from './isSafariStack';

/**
 * Update the stack of an error without changing the first line containing the type and message
 */
export function updateStack<ERR extends Error = Error>(err: ERR, stack: string | undefined): ERR {
	const firstLine = err.stack?.match(/^([^\n]*)/)?.[0] || 'Error';

	if (isSafariStack(err)) {
		// weird Safari stacktrace that doesn't contain the error message
		// we'll assume that the new stack has a similar issue

		// eslint-disable-next-line no-param-reassign
		err.stack = stack;
	} else {
		const body = stack?.replace(/^[^\n]*\n/, '');

		// eslint-disable-next-line no-param-reassign
		err.stack = body ? `${firstLine}\n${body}` : firstLine;
	}

	return err;
}
