/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/naming-convention */
import type { Params } from 'react-router';
import { useParams } from 'react-router';

/**
 * useParams with a different return type where values cannot be "undefined"
 */
export const useKnownAvailableParams: <
	ParamsOrKey extends string | Record<string, string | undefined> = string,
>() => Readonly<[ParamsOrKey] extends [string] ? NonNullable<Params<ParamsOrKey>> : ParamsOrKey> = useParams as any;
