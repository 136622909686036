import { Parser } from 'htmlparser2';

export function stripHtmlTags(html: string): string {
	const result: string[] = [];

	let ignored = false;

	const parser = new Parser(
		{
			onopentag(name) {
				if (['script', 'style'].includes(name)) {
					ignored = true;
				}
			},
			ontext(text) {
				!ignored && result.push(text);
			},
			onclosetag() {
				ignored = false;
			},
		},
		{ decodeEntities: true },
	);

	parser.write(html);
	parser.end();

	return result.join('');
}
