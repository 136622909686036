import type { JSX, PropsWithChildren } from 'react';
import { useEffect, useState } from 'react';

import type { PrefetchContextData, PrefetchSessionContextData, PrefetchUserContextData } from './context';
import { PrefetchContext, PrefetchSessionContext, PrefetchUserContext } from './context';

type Props = Readonly<{
	prefetchedData?: PrefetchContextData;
	prefetchedUserData?: PrefetchUserContextData;
	prefetchedSession?: PrefetchSessionContextData;
	clearAfterFirstRender?: boolean;
}>;

export function PrefetchProvider({
	prefetchedData,
	prefetchedUserData,
	prefetchedSession,
	clearAfterFirstRender,
	children,
}: PropsWithChildren<Props>): JSX.Element | null {
	const [data, setData] = useState<PrefetchContextData>(prefetchedData);
	const [userData, setUserData] = useState<PrefetchUserContextData>(prefetchedUserData);
	const [session, setSession] = useState<PrefetchSessionContextData>(prefetchedSession);

	useEffect(() => {
		if (!clearAfterFirstRender) return;
		// prefetched data is only available on first render
		setData(undefined);
		setUserData(undefined);
		setSession(undefined);
	}, [clearAfterFirstRender]);

	return (
		<PrefetchSessionContext.Provider value={clearAfterFirstRender ? session : prefetchedSession}>
			<PrefetchContext.Provider value={clearAfterFirstRender ? data : prefetchedData}>
				<PrefetchUserContext.Provider value={clearAfterFirstRender ? userData : prefetchedUserData}>
					{children}
				</PrefetchUserContext.Provider>
			</PrefetchContext.Provider>
		</PrefetchSessionContext.Provider>
	);
}
