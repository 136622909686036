import { ApolloLink } from '@apollo/client';

const CONSERVED_HEADERS = ['x-request-id'];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function extractExtensionsFromResponse(response: Response): Record<string, any> {
	const { headers, status } = response;

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const extensions: Record<string, any> = {};

	// eslint-disable-next-line no-param-reassign, @typescript-eslint/no-unsafe-assignment
	extensions.status = status;

	// eslint-disable-next-line no-param-reassign
	extensions.headers = CONSERVED_HEADERS.reduce<Record<string, string>>((acc, name) => {
		const value = headers.get(name);
		if (!value) return acc;
		return { ...acc, [name]: value };
	}, {});

	return extensions;
}

const uid = function () {
	return Date.now().toString(36) + Math.random().toString(36).substring(2);
};

export const operationIdLink = new ApolloLink((operation, forward) => {
	const operationId = uid();
	// operationId is a custom extension property handled by fe's apollo plugins
	// eslint-disable-next-line no-param-reassign
	operation.extensions.operationId = operationId;

	return forward(operation);
});
