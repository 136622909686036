import type { Observable } from '@apollo/client';

// https://github.com/apollographql/apollo-link/blob/638b78672a9b4adc5283e5710150da701e8e4bbd/packages/apollo-link/src/linkUtils.ts#L39
export async function makePromise<R>(observable: Observable<R>): Promise<R> {
	let completed = false;
	// eslint-disable-next-line promise/avoid-new
	return new Promise<R>((resolve, reject) => {
		observable.subscribe({
			next: (data) => {
				if (completed) {
					// eslint-disable-next-line no-console
					console.warn('Promise Wrapper does not support multiple results from Observable');
				} else {
					completed = true;
					resolve(data);
				}
			},
			error: reject,
		});
	});
}
