import React from 'react';
import type { JSX } from 'react';

import type { Element, Node } from 'domhandler';
import { isTag } from 'domhandler';
import type { Config } from 'dompurify';
import type { DOMNode, HTMLReactParserOptions } from 'html-react-parser';
import htmlReactParser, { domToReact as parserDomToReact } from 'html-react-parser';

import { DOMPurify } from '@change-corgi/core/dompurify';
import { stripHtmlTags } from '@change-corgi/core/html';

import type { HtmlTransformer } from './types';

type DomToReactFn = (nodes: readonly Node[]) => string | JSX.Element | JSX.Element[];
type Context = Readonly<{
	domToReact: DomToReactFn;
}>;

export type HtmlParserReplaceFn = (element: Element, context: Context) => JSX.Element | null | undefined;

type Options = Readonly<{
	replace?: HtmlParserReplaceFn;
	/**
	 * Replace default list of allowed tags
	 */
	restrictedTags?: readonly string[];
	/**
	 * Add tags to default list of allowed tags
	 */
	allowedTags?: readonly string[];
	/**
	 * Remove tags from default list of allowed tags
	 */
	forbiddenTags?: readonly string[];
	/**
	 * Replace default list of allowed attributes
	 */
	restrictedAttrs?: readonly string[];
	/**
	 * Add attributes to default list of allowed attributes
	 */
	allowedAttrs?: readonly string[];
	/**
	 * Remove attributes from default list of allowed attributes
	 */
	forbiddenAttrs?: readonly string[];
	/**
	 * Remove attributes from default list of allowed attributes
	 */
	transformers?: readonly HtmlTransformer[];
	/**
	 * adds suppressHydrationWarning prop to all elements
	 *
	 * see also https://react.dev/reference/react-dom/client/hydrateRoot#suppressing-unavoidable-hydration-mismatch-errors
	 */
	suppressHydrationWarning?: boolean;
}>;

type DompurifyConfig = Config & { RETURN_DOM_FRAGMENT?: false; RETURN_DOM?: false };

function createDompurifyOptions(options: Omit<Options, 'replace'>): DompurifyConfig {
	const config: DompurifyConfig = {};
	options.allowedTags && (config.ADD_TAGS = [...options.allowedTags]);
	options.allowedAttrs && (config.ADD_ATTR = [...options.allowedAttrs]);
	options.forbiddenTags && (config.FORBID_TAGS = [...options.forbiddenTags]);
	options.forbiddenAttrs && (config.FORBID_ATTR = [...options.forbiddenAttrs]);
	options.restrictedTags && (config.ALLOWED_TAGS = [...options.restrictedTags]);
	options.restrictedAttrs && (config.ALLOWED_ATTR = [...options.restrictedAttrs]);
	return config;
}

function sanitize(html: string, options: Omit<Options, 'replace'>, retries: number): string {
	try {
		return DOMPurify.sanitize(html, createDompurifyOptions(options));
	} catch (e) {
		if (retries > 0) {
			return sanitize(html, options, retries - 1);
		}
		throw e;
	}
}

export function parseHtml(
	html: string,
	{ replace, transformers = [], suppressHydrationWarning, ...restOptions }: Options = {},
): ReturnType<typeof htmlReactParser> {
	if (!DOMPurify.isSupported) {
		return stripHtmlTags(html);
	}

	try {
		const cleanHtml = sanitize(html, restOptions, 2);

		const transformedHtml = transformers.reduce(
			(updatedHtml, transformer) => transformer.transformHtml(updatedHtml),
			cleanHtml,
		);

		const library: HTMLReactParserOptions['library'] | undefined = suppressHydrationWarning
			? {
					...React,
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					createElement: (type: any, props?: object, ...children: any) => {
						// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
						return React.createElement(type, { ...props, suppressHydrationWarning: true }, ...children);
					},
				}
			: undefined;

		const options = {
			replace:
				replace &&
				((node: DOMNode) =>
					isTag(node)
						? replace(node, { domToReact: (nodes) => parserDomToReact(nodes as DOMNode[], options) })
						: undefined),
			library,
		};

		return htmlReactParser(transformedHtml, options);
	} catch (e) {
		return stripHtmlTags(html);
	}
}

export { domToReact } from 'html-react-parser';

export type ParseHtmlOptions = Options;
