// for mocking

export function getWindow(): Window & typeof globalThis {
	if (typeof window === 'undefined') {
		throw new Error('window is not available in this environment');
	}
	return window;
}

export function getWindowSafe(): (Window & typeof globalThis) | undefined {
	if (typeof window === 'undefined') {
		return undefined;
	}
	return window;
}

export function getLocation(): Location {
	return getWindow().location;
}

export function openWindow(url: string): Window | null {
	return getWindow().open(url);
}

export function getHistory(): History {
	return getWindow().history;
}

export function getDocument(): Document {
	return getWindow().document;
}

export function getDocumentTitle(): string {
	return getDocument().title;
}

export function getLocalStorageSafe(): Storage | undefined {
	return getWindowSafe()?.localStorage;
}

export function alertWindow(message: string): void {
	return getWindow().alert(message);
}

export function confirmWindow(message: string): boolean {
	return getWindow().confirm(message);
}
