import type { PageState, State } from './PageState';
import { createPageState } from './PageState';

export type StaticPageStateContext = {
	state: State;
};

type Options = Readonly<{
	context: StaticPageStateContext;
}>;

export function createStaticPageState({ context }: Options): PageState {
	return createPageState({
		set: (state) => {
			// eslint-disable-next-line no-param-reassign
			context.state = state;
		},
		get: () => context.state,
	});
}
