import type { FetchResult } from '@apollo/client';
import { ApolloLink, Observable } from '@apollo/client';
import type { DocumentNode, GraphQLFormattedError, OperationDefinitionNode } from 'graphql';
import { Kind } from 'graphql';

import { trimStack, updateStack } from '@change-corgi/core/error';
import type { ReportableError, ReportOptions } from '@change-corgi/core/errorReporter/common';

function getOperationName(query: DocumentNode): string | undefined {
	const operationDef: OperationDefinitionNode | undefined = query.definitions.find(
		({ kind }) => kind === Kind.OPERATION_DEFINITION,
	) as unknown as OperationDefinitionNode | undefined;
	return operationDef?.name?.value;
}

function genErrorForReporter({
	url,
	err,
	query,
	initiatorStack,
	initiatorStackTrim,
}: {
	url: string;
	err: GraphQLFormattedError;
	query: DocumentNode;
	initiatorStack: string;
	initiatorStackTrim: number;
}): ReportableError {
	const headers = (err.extensions?.headers || {}) as Record<string, string>;
	return {
		error: trimStack(
			updateStack(new Error(`Error in GQL response body: ${err.message}`), initiatorStack),
			initiatorStackTrim,
		),
		params: {
			url,
			query: query.loc?.source.body,
			locations: err.locations,
			path: err.path,
			status: err.extensions?.status,
			originalErrorReportUrl: err.extensions?.errorReportUrl,
		},
		context: {
			requestId: headers['x-request-id'],
			graphql: {
				operationName: getOperationName(query),
			},
		},
	};
}

// eslint-disable-next-line max-lines-per-function
export const errorHandlerLink = (reportError: (error: ReportableError, options?: ReportOptions) => void): ApolloLink =>
	// eslint-disable-next-line max-lines-per-function
	new ApolloLink((operation, forward) => {
		const operationId = operation.extensions.operationId as string | undefined;
		const { query } = operation;
		const url = operation.getContext().uri as string;
		// these are set in GqlClient.ts
		const initiatorStack = operation.getContext().stack as string;
		const initiatorStackTrim = operation.getContext().stackTrim as number;

		return new Observable((observer) => {
			const sub = forward(operation).subscribe({
				next: (result) => {
					if (result.errors) {
						result.errors.forEach((err) =>
							reportError(genErrorForReporter({ err, url, query, initiatorStack, initiatorStackTrim }), {
								// GQLResponseError is the one that should be reported as error
								// these are reported as warning so we can detect trends in GQL errors
								severity: 'warning',
							}),
						);
					}

					observer.next(result);
				},
				error: (networkError) => {
					// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
					const result = networkError?.result as FetchResult | readonly FetchResult[] | undefined;
					// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
					const response = networkError?.response as Response | undefined;

					if (result && response) {
						if (!Array.isArray(result)) {
							// eslint-disable-next-line @typescript-eslint/no-explicit-any
							(result as FetchResult).errors?.forEach((err: any) =>
								reportError(
									// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
									genErrorForReporter({ err, url, query, initiatorStack, initiatorStackTrim }),
									{
										// GQLResponseServerError is the one that should be reported as error
										// these are reported as warning so we can detect trends in GQL errors
										severity: 'warning',
									},
								),
							);
						} else {
							(result as readonly FetchResult[]).forEach((resultItem) => {
								// making sure to only treat the current operation
								// as resultArray unfortunately contains the result of all batched operations
								if (resultItem.extensions?.operationId !== operationId) {
									return;
								}

								// eslint-disable-next-line @typescript-eslint/no-explicit-any
								resultItem.errors?.forEach((err: any) =>
									reportError(
										// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
										genErrorForReporter({ err, url, query, initiatorStack, initiatorStackTrim }),
										{
											// GQLResponseServerError is the one that should be reported as error
											// these are reported as warning so we can detect trends in GQL errors
											severity: 'warning',
										},
									),
								);
							});
						}
					}

					observer.error(networkError);
				},
			});

			return () => {
				sub.unsubscribe();
			};
		});
	});
