import { ApolloLink } from '@apollo/client';

import { isSsr } from '@change-corgi/core/ssr';

export type WebappInfo = Readonly<{
	/**
	 * name of app
	 */
	name?: string;
	buildTsUtc?: string;
	version?: string;
	versionNormalized?: string;
}>;

export const webappInfoLink = (webappInfo: WebappInfo | undefined): ApolloLink =>
	new ApolloLink((operation, forward) => {
		// eslint-disable-next-line no-param-reassign
		operation.extensions.webappInfo = {
			name: webappInfo?.name,
			build_ts_utc: webappInfo?.buildTsUtc,
			version: webappInfo?.version,
			version_normalized: webappInfo?.versionNormalized,
			ssr: isSsr(),
		};

		return forward(operation);
	});
