import type { HttpClient } from '@change-corgi/core/http';

import { getHttpMode } from './getHttpMode';

// using /et to avoid having some AdBlockers block the endpoint based of the presence of "track" in the name
const API_ENDPOINT = '/api-proxy/-/et';

type Payload = {
	label: string;
	data: Record<string, unknown>;
};

export type Options = Readonly<{
	/**
	 * Sets the mode for sending tracking events
	 *
	 * - fetch: uses fetch with keepalive: true
	 * - beacon: uses sendBeacon with the CSRF token as a query param
	 *
	 * If set to 'fetch', will still fall back to using sendBeacon if "keepalive" is not supported, but sendBeacon is (older browsers)
	 *
	 * If set to 'beacon', will still fall back to using fetch (even without keepalive) if sendBeacon is not supported (very old browsers, or node server)
	 *
	 * default is 'fetch'
	 */
	httpMode?: 'fetch' | 'beacon';
	post: HttpClient['post'];
	sendBeacon: HttpClient['sendBeacon'];
	getCsrfToken: () => Promise<string>;
}>;

export async function sendPayload(
	payload: readonly Payload[] | Payload,
	{ httpMode = 'fetch', post, sendBeacon, getCsrfToken }: Options,
): Promise<boolean> {
	if (getHttpMode(httpMode) === 'fetch') {
		// keepalive is the new way of doing what sendBeacon was used for
		// but is not supported everywhere (e.g. Firefox)
		// https://javascript.info/fetch-api#keepalive
		try {
			await post(API_ENDPOINT, payload, {
				keepalive: true,
				// force mode to be same-origin due to bug in older browsers
				// https://bugs.chromium.org/p/chromium/issues/detail?id=835821
				mode: 'same-origin',
			});
			return true;
		} catch (e) {
			return false;
		}
	}

	// fall back to sendBeacon for browsers which do not support keepalive
	const csrfToken = await getCsrfToken();
	return sendBeacon(`${API_ENDPOINT}?_csrf=${csrfToken}`, payload);
}
