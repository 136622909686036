/* eslint-disable @typescript-eslint/consistent-indexed-object-style */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMemo } from 'react';

import cloneDeep from 'lodash/cloneDeep';

import type { EmptyIntersection, EmptyObject } from '@change-corgi/core/types';

type CacheMutation<STATE extends EmptyIntersection> = (state: STATE, ...payload: any[]) => STATE;
type CacheMutations<STATE extends EmptyIntersection> = Record<string, CacheMutation<STATE>>;

export type CacheResultMutations<MUTATIONS extends CacheMutations<any>> = {
	// using infer to conserve arg names
	[key in keyof MUTATIONS]: MUTATIONS[key] extends (state: any, ...args: infer ARGS) => any
		? (...args: ARGS) => void
		: never;
};

type CachePrefetchContextStateItem<STATE extends EmptyIntersection, MUTATIONS extends CacheMutations<STATE>> = {
	getState: () => STATE;
} & CacheResultMutations<MUTATIONS>;

type CachePrefetchContext<
	STATE extends EmptyIntersection,
	MUTATIONS extends CacheMutations<STATE>,
	KEY extends string,
> = {
	[key in KEY]: CachePrefetchContextStateItem<STATE, MUTATIONS>;
};

type CachePrefetchContextStateItemFunctions<
	STATE extends EmptyIntersection,
	MUTATIONS extends CacheMutations<STATE>,
	KEY extends string,
> = {
	useCachePrefetchContext: () => CachePrefetchContext<STATE, MUTATIONS, KEY>;
	createCachePrefetchContext: () => CachePrefetchContext<STATE, MUTATIONS, KEY>;
};

type Options<
	STATE extends EmptyIntersection = EmptyObject,
	MUTATIONS extends CacheMutations<STATE> = EmptyObject,
	KEY extends string = string,
> = {
	/**
	 * key in which the functions will be set inside the returned object
	 */
	cacheKey: KEY;
	/**
	 * hook that should return the state and a list of mutations (see @change-corgi/core/react/context's state context)
	 */
	useState: () => [STATE, CacheResultMutations<MUTATIONS>];
	/**
	 * list of mutations (see @change-corgi/core/react/context's state context) that will be used to generate the prefetch function
	 */
	mutations: MUTATIONS;
	/**
	 * initial state that will be used to generate the prefetch function
	 */
	initialState: STATE;
};

// eslint-disable-next-line max-lines-per-function
export function createCachePrefetchContextStateItemFunctions<
	STATE extends EmptyIntersection = EmptyObject,
	MUTATIONS extends CacheMutations<STATE> = EmptyObject,
	KEY extends string = string,
>({
	cacheKey,
	useState,
	mutations: prefetchMutations,
	initialState,
}: Options<STATE, MUTATIONS, KEY>): CachePrefetchContextStateItemFunctions<STATE, MUTATIONS, KEY> {
	return {
		useCachePrefetchContext: (): CachePrefetchContext<STATE, MUTATIONS, KEY> => {
			const [state, mutations] = useState();

			return useMemo(
				() =>
					({
						[cacheKey]: {
							getState: () => state,
							...mutations,
						},
					}) as unknown as CachePrefetchContext<STATE, MUTATIONS, KEY>,
				[state, mutations],
			);
		},
		createCachePrefetchContext: (): CachePrefetchContext<STATE, MUTATIONS, KEY> => {
			// since cache object usually use mutation to conserve references for avoiding unnecessary rerenders by the react lifecycle,
			// we are deep cloning to make sure we're not conserving the same references in between server renders
			const state: STATE = cloneDeep(initialState);
			return {
				[cacheKey]: {
					getState: () => state,
					...Object.entries(prefetchMutations).reduce<Record<string, any>>((acc, [name, fn]: [string, any]) => {
						// eslint-disable-next-line no-param-reassign, @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-call
						acc[name] = (...args: any[]) => fn(state, ...args);
						return acc;
					}, {}),
				},
			} as unknown as CachePrefetchContext<STATE, MUTATIONS, KEY>;
		},
	};
}
