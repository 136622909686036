export type State = Readonly<Record<string, unknown>>;

type Options = Readonly<{
	set: (state: State) => void;
	get: () => State;
}>;

class PageState {
	private set: (state: State) => void;
	private get: () => State;

	constructor({ set, get }: Options) {
		this.set = set;
		this.get = get;
	}

	setValue(name: string, value: unknown): () => void {
		this.set({ ...this.get(), [name]: value });

		return () => {
			const cleaned = { ...this.get() };
			delete cleaned[name];
			this.set(cleaned);
		};
	}
}

export type { PageState };

export function createPageState(options: Options): PageState {
	return new PageState(options);
}
