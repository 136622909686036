import type { JSX, PropsWithChildren } from 'react';
import { useEffect, useState } from 'react';

import type { EmptyIntersection } from '@change-corgi/core/types';

import { AppRenderContextProvider } from './context';

export function AppRenderInfoProvider({ children }: PropsWithChildren<EmptyIntersection>): JSX.Element | null {
	const [firstRender, setFirstRender] = useState(true);

	useEffect(() => {
		setFirstRender(false);
	}, []);

	return <AppRenderContextProvider value={{ firstRender }}>{children}</AppRenderContextProvider>;
}
