import type { NavigateOptions } from 'react-router';

import { getLocation } from '@change-corgi/core/window';

export function navigateExternal(to: string, { replace }: Pick<NavigateOptions, 'replace'> = {}): void {
	if (replace) {
		getLocation().replace(to);
		return;
	}
	getLocation().assign(to);
}
