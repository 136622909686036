function resolveInput(input: string, origin?: string): string;
function resolveInput(input: RequestInfo, origin?: string): RequestInfo;
function resolveInput(input: RequestInfo, origin?: string): RequestInfo | string {
	if (!origin) {
		return input;
	}
	if (typeof input === 'string') {
		if (input.startsWith('/')) {
			return `${origin}${input}`;
		}
		if (!/^https?:\/\//.exec(input)) {
			throw new Error('Auto-prefixing relative url not supported at this point');
		}
		return input;
	}
	if (input.url.startsWith('/')) {
		throw new Error('Auto-prefixing Request input not supported at this point');
	}
	if (!/^https?:\/\//.exec(input.url)) {
		throw new Error('Auto-prefixing relative Request input not supported at this point');
	}
	return input;
}

export { resolveInput };
