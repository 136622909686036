import type { ReportableError, ReportOptions } from '@change-corgi/core/errorReporter/common';

import { getFetchResponseError } from './getFetchResponseError';
import { getFilteredHeaders } from './getFilteredHeaders';
import { getRequestGqlDetails } from './getRequestGqlDetails';
import { getRequestMethod } from './getRequestMethod';
import { getRequestUrl } from './getRequestUrl';

export function reportResponseError(
	init: RequestInit | undefined,
	info: RequestInfo | URL,
	response: Response,
	reportError: (error: ReportableError, options?: ReportOptions) => void,
): void {
	const requestId = response.headers?.get('x-request-id') || undefined;

	const gqlDetails = getRequestGqlDetails(init);

	const error = getFetchResponseError(response);

	// note: this will be reported alongside the reported errors in GqlClient for non-5xx errors
	void reportError(
		{
			// TODO switch to http-errors?
			error,
			params: {
				message: response.statusText,
				status: response.status,
				url: getRequestUrl(info),
				method: getRequestMethod(info, init),
				gqlDetails,
				cfRay: response.headers?.get('cf-ray'),
				...getFilteredHeaders(response),
			},
			context: {
				requestId,
				graphql: gqlDetails && {
					batched: gqlDetails.batched,
					operationName: [...gqlDetails.operationNames].sort().join(','),
				},
			},
		},
		{
			// GQLResponseServerError is the one that should be reported as error
			// these are reported as warning so we can detect trends in GQL errors
			severity: 'warning',
		},
	);
}
