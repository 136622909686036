import type { JSX } from 'react';

import type { TranslationKeyArg } from '@change-corgi/core/i18n';

import type { JsxReplacements, TranslateHtmlOptions } from '../misc';
import { useI18n } from '../misc';

type Props = Readonly<{
	value: TranslationKeyArg;
	replacements?: JsxReplacements;
}> &
	TranslateHtmlOptions;

export function TranslateHtml({ value, replacements, ...options }: Props): JSX.Element {
	const { translateHtml } = useI18n();

	return <>{translateHtml(value, replacements, options)}</>;
}
