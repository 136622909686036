import { getWindowSafe } from '@change-corgi/core/window';

// TODO: could be made into a IIFE for optimization
export const hasLocalStorage = (): boolean => {
	const window = getWindowSafe();
	if (window === undefined) return false;

	const t = 't';
	let localStorage;
	try {
		localStorage = window.localStorage;
		localStorage.setItem(t, t);
		localStorage.removeItem(t);
		return true;
	} catch (e) {
		return false;
	}
};

export const setItem = (key: string, data: string): boolean => {
	if (!hasLocalStorage()) return false;

	const window = getWindowSafe() as Window;
	window.localStorage.setItem(key, data);
	return true;
};

export const removeItem = (key: string): boolean => {
	if (!hasLocalStorage()) return false;

	const window = getWindowSafe() as Window;
	window.localStorage.removeItem(key);
	return true;
};

export const getItem = (key: string): string | null => {
	if (!hasLocalStorage()) return null;

	const window = getWindowSafe() as Window;
	return window.localStorage.getItem(key);
};
