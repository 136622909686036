import { gql } from 'graphql-tag';

import type { ReportableError } from '@change-corgi/core/errorReporter/common';
import type { GqlClient } from '@change-corgi/core/gql';

import type { FeCoreSessionQuery, FeCoreSessionQueryVariables, FeSessionUserFragment } from './session.graphql';

type SessionInitial = FeCoreSessionQuery['session'];
export type SessionUser = FeSessionUserFragment & {
	/**
	 * if undefined, value couldn't be retrieved due to permissions
	 *
	 * if null, value is not set in the DB
	 */
	createdAt?: string | null;
};
export type SessionUserRole = keyof Omit<
	SessionUser['roles'],
	'id' | 'countryTeam' | 'countryTeamAdmin' | '__typename'
>;
export const SESSION_USER_ROLES = Object.keys({
	audit: true,
	auditAdmin: true,
	campaigns: true,
	data: true,
	engineering: true,
	petitionAdmin: true,
	policy: true,
	policyAdmin: true,
	policySenior: true,
	product: true,
	staff: true,
	staffAdmin: true,
	support: true,
	supportAdmin: true,
	supportSenior: true,
	userResearch: true,
	communications: false,
} satisfies Record<SessionUserRole, boolean>).sort() as readonly SessionUserRole[];
export type SessionUserScopedRole = keyof Pick<SessionUser['roles'], 'countryTeam' | 'countryTeamAdmin'>;
export const SESSION_USER_SCOPED_ROLES = Object.keys({
	countryTeam: true,
	countryTeamAdmin: true,
} satisfies Record<SessionUserScopedRole, boolean>).sort() as readonly SessionUserScopedRole[];
export type SessionUserScopedRoleDetails = SessionUser['roles']['countryTeam'][0];

type BaseSession = Omit<SessionInitial, 'user' | 'userAuthOnly' | 'loginState' | 'id'>;

export type GuestSession = BaseSession &
	Readonly<{
		loginState: Extract<SessionInitial['loginState'], 'GUEST'>;
		user: null;
	}>;
// TODO possible improvement: use discriminant to only add createdAt for loginState==AUTHENTICATED
export type AuthSession = BaseSession &
	Readonly<{
		loginState: Exclude<SessionInitial['loginState'], 'GUEST'>;
		user: SessionUser;
	}>;

export type Session = GuestSession | AuthSession;

type Options = Readonly<{
	gqlFetch: GqlClient['fetch'];
	reportError: (error: ReportableError) => void;
}>;

const SESSION_QUERY = gql`
	query FeCoreSession {
		session: viewer {
			id
			loginState
			csrfToken
			locale {
				localeCode
			}
			country {
				countryCode
				membershipProgram {
					id
				}
			}
			ipCountry {
				countryCode
			}
			geoipData {
				region
				city
				postalCode
				countryCode
			}
			trackingData
			cookiePrefs {
				preferences
				analytics
				marketing
			}
			cookiePrefsSaved
			uuid
			user {
				id
				...FeSessionUser
			}
			userAuthOnly: user(ifAuthenticated: true) {
				id
				createdAt
			}
			experiments {
				id
				variation
				treated
			}
			hasMembership
		}
	}
	fragment FeSessionUser on User {
		id
		slug
		roles {
			id
			staff
			staffAdmin: hasRole(targetType: "Staff", title: "Admin")
			support
			supportSenior: hasRole(targetType: "Support", title: "SeniorMember")
			supportAdmin: hasRole(targetType: "Support", title: "Admin")
			audit
			auditAdmin
			campaigns: hasRole(targetType: "Campaigns", title: "Member")
			engineering: hasRole(targetType: "Engineering", title: "Member")
			petitionAdmin: hasRole(targetType: "Petition", title: "Admin")
			product: hasRole(targetType: "Product", title: "Member")
			userResearch: hasRole(targetType: "UserResearch", title: "Member")
			policy: hasRole(targetType: "Policy", title: "Member")
			policySenior: hasRole(targetType: "Policy", title: "SeniorMember")
			policyAdmin: hasRole(targetType: "Policy", title: "Admin")
			data: hasRole(targetType: "Data", title: "Member")
			communications: hasRole(targetType: "Communications", title: "Member")
			countryTeam: countryTeam(title: "Member") {
				scope
			}
			countryTeamAdmin: countryTeam(title: "Admin") {
				scope
			}
		}
		newUser
		passwordSet
		firstName
		lastName
		displayName
		shortDisplayName
		email
		country {
			countryCode
		}
		city
		postalCode
		stateCode
		formattedLocationString
		totalSignatureCount
		photo {
			id
			userSmall {
				url
				processing
				width
				height
			}
			userMedium {
				url
				processing
				width
				height
			}
			userLarge {
				url
				processing
				width
				height
			}
		}
		activityRaw
		connectedToFacebook
		facebookHash
	}
`;

export async function getSession({ gqlFetch, reportError }: Options): Promise<Session> {
	const response = await gqlFetch<FeCoreSessionQuery, FeCoreSessionQueryVariables>({
		important: true,
		query: SESSION_QUERY,
		rejectOnError: true,
	});
	const sessionWithId = response.data?.session;
	if (!sessionWithId) {
		throw new Error('Cannot retrieve session');
	}
	const { id, ...session } = sessionWithId;
	if (session.loginState !== 'GUEST' && !session.user) {
		// should not happen => let's report it
		const invalidSession = session as GuestSession;
		reportError({
			error: `user info missing in Viewer for ${invalidSession.loginState} user`,
			params: {
				loginState: invalidSession.loginState,
				uuid: invalidSession.uuid,
			},
		});
		// assume the session to be a guest session to avoid issues
		return {
			...invalidSession,
			loginState: 'GUEST' as const,
		} as GuestSession;
	}
	if (session.user && session.userAuthOnly) {
		session.user = {
			...session.user,
			...session.userAuthOnly,
		};
	}
	// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access
	delete (session as any).userAuthOnly;
	return session as Session;
}
