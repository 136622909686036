/* istanbul ignore file */
import qs from 'qs';

import { addScript } from '@change-corgi/core/dom';
import type { ReportableError } from '@change-corgi/core/errorReporter/common';

export function lookupSdkHash(): string | undefined {
	const scriptElement = Array.from(
		document.querySelectorAll('script[src^="https://connect.facebook.net/"][src*="hash="]'),
	)[0] as HTMLScriptElement | undefined;
	if (!scriptElement) {
		return undefined;
	}
	return qs.parse(scriptElement.src.split('?')[1]).hash as string;
}

/* eslint-disable max-lines-per-function */
export async function initSdk({
	appId,
	apiVersion,
	locale,
	reportError,
}: {
	appId: string;
	apiVersion: string;
	locale: string;
	reportError: (error: ReportableError) => void;
}): Promise<fb.FacebookStatic> {
	const sdkUrl = `https://connect.facebook.net/${locale}/sdk.js`;
	// eslint-disable-next-line promise/avoid-new
	return new Promise<fb.FacebookStatic>((resolve, reject) => {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access
		(window as any).fbAsyncInit = () => {
			FB.init({
				appId,
				autoLogAppEvents: true,
				cookie: false,
				xfbml: true,
				version: `v${apiVersion}`,
			});
			resolve(FB);
		};
		addScript(sdkUrl)
			// eslint-disable-next-line promise/prefer-await-to-then
			.catch((e) => {
				// eslint-disable-next-line promise/catch-or-return, promise/no-nesting
				fetch(sdkUrl)
					// eslint-disable-next-line promise/prefer-await-to-then, promise/no-nesting, @typescript-eslint/prefer-promise-reject-errors
					.then(async (response) => (response.ok ? Promise.resolve() : Promise.reject(response)))
					// eslint-disable-next-line promise/prefer-await-to-then, promise/no-nesting
					.then(
						() => {
							reportError({
								error: new Error('Could not run facebook SDK script'),
							});
							return undefined;
						},
						(response) => {
							reportError({
								error: new Error('Could not retrieve facebook SDK script'),
								/* eslint-disable @typescript-eslint/no-unsafe-member-access */
								params: response.status
									? {
											message: response.statusText,
											status: response.status,
											url: sdkUrl,
										}
									: {
											// eslint-disable-next-line @typescript-eslint/no-unsafe-call
											message: response.toString(),
											url: sdkUrl,
										},
								/* eslint-enable @typescript-eslint/no-unsafe-member-access */
							});
						},
					);
				// eslint-disable-next-line @typescript-eslint/prefer-promise-reject-errors
				reject(e);
			});
	});
}
/* eslint-enable max-lines-per-function */
