import set from 'lodash/fp/set';

import type { Translations } from '@change-corgi/core/i18n';

const EN_PLURAL_KEY = 'other';
const OTHER_PLURALS = ['zero', 'two', 'few', 'many'];

function findPluralKeys(
	translations: Translations,
	res: Record<string, string> = {},
	prefix: string | undefined = undefined,
): Record<string, string> {
	return Object.entries(translations).reduce<Record<string, string>>((acc, [key, value]) => {
		if (prefix && typeof value === 'string' && key === EN_PLURAL_KEY) {
			OTHER_PLURALS.forEach((otherPluralKey) => {
				// eslint-disable-next-line no-param-reassign
				acc[`${prefix}.${otherPluralKey}`] = value;
			});
			return acc;
		}
		if (typeof value === 'object') {
			return findPluralKeys(value, acc, prefix ? `${prefix}.${key}` : key);
		}
		return acc;
	}, res);
}

export function generateAdditionalPlurals(translations: Translations | readonly Translations[]): Translations {
	const array: readonly Translations[] = Array.isArray(translations) ? translations : [translations];
	return array
		.map((tr) => findPluralKeys(tr))
		.reduce<Translations>((acc, additionalPlurals) => {
			return Object.entries(additionalPlurals).reduce((innerAcc, [key, value]) => {
				return set(key, value, innerAcc);
			}, acc);
		}, {});
}
