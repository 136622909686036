import type { FetchResult } from '@apollo/client';
import { ApolloLink, Observable } from '@apollo/client';

const CONSERVED_HEADERS = ['x-request-id'];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function extractExtensionsFromResponse(response: Response): Record<string, any> {
	const { headers, status } = response;

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const extensions: Record<string, any> = {};

	// eslint-disable-next-line no-param-reassign, @typescript-eslint/no-unsafe-assignment
	extensions.status = status;

	// eslint-disable-next-line no-param-reassign
	extensions.headers = CONSERVED_HEADERS.reduce<Record<string, string>>((acc, name) => {
		const value = headers.get(name);
		if (!value) return acc;
		return { ...acc, [name]: value };
	}, {});

	return extensions;
}

export const responseInfoLink = new ApolloLink((operation, forward) => {
	const operationId = operation.extensions.operationId as string | undefined;

	return new Observable((observer) => {
		const sub = forward(operation).subscribe({
			next: (result) => {
				const context = operation.getContext();

				if (result.errors) {
					const extensions = extractExtensionsFromResponse(context.response as Response);

					result.errors.forEach((error) => {
						if (!error.extensions) return;
						Object.assign(error.extensions, extensions);
					});
				}

				if (context.response) {
					// eslint-disable-next-line no-param-reassign
					result.extensions = result.extensions || {};
					// eslint-disable-next-line no-param-reassign
					result.extensions.requestId = (context.response as Response).headers.get('x-request-id') || undefined;
				}

				observer.next(result);
			},
			error: (networkError) => {
				// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
				const result = networkError?.result as FetchResult | readonly FetchResult[] | undefined;
				// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
				const response = networkError?.response as Response | undefined;

				if (result && response) {
					// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
					const extensions = extractExtensionsFromResponse(response);

					if (!Array.isArray(result)) {
						(result as FetchResult).errors?.forEach((error) => {
							if (!error.extensions) return;
							Object.assign(error.extensions, extensions);
						});
					} else {
						(result as readonly FetchResult[]).forEach((resultItem) => {
							// making sure to only treat the current operation
							// as resultArray unfortunately contains the result of all batched operations
							if (resultItem.extensions?.operationId !== operationId) {
								return;
							}

							resultItem.errors?.forEach((error) => {
								if (!error.extensions) return;
								Object.assign(error.extensions, extensions);
							});
						});
					}
				}

				observer.error(networkError);
			},
		});

		return () => {
			sub.unsubscribe();
		};
	});
});
