import type { JSX } from 'react';

import { Navigate } from './Navigate';
import type { NavigateProps } from './Navigate';

type RedirectProps = Omit<NavigateProps, 'replace'> & { push?: boolean };

export function Redirect({ push, ...props }: RedirectProps): JSX.Element {
	return <Navigate replace={!push} {...props} />;
}
