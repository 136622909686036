import type { JSX } from 'react';

import type { NumberOptions } from '@change-corgi/core/i18n';

import { useI18n } from '../misc';

type Props = Readonly<{
	value: number;
	options?: NumberOptions;
}>;

export function LocalizeNumber({ value, options }: Props): JSX.Element {
	const { localizeNumber } = useI18n();

	return <>{localizeNumber(value, options)}</>;
}
