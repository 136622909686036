import type { JSX, PropsWithChildren } from 'react';
import { useMemo } from 'react';

import type { StaticPageStateContext } from '@change-corgi/core/pageState';
import { createStaticPageState, createWindowPageState } from '@change-corgi/core/pageState';
import type { EmptyProps } from '@change-corgi/core/types';

import { PageStateProvider } from './context';

export function WindowPageStateProvider({ children }: PropsWithChildren<EmptyProps>): JSX.Element | null {
	const value = useMemo(() => createWindowPageState(), []);

	return <PageStateProvider value={value}>{children}</PageStateProvider>;
}

export function StaticPageStateProvider({
	context,
	children,
}: PropsWithChildren<{ context: StaticPageStateContext }>): JSX.Element | null {
	const value = useMemo(() => createStaticPageState({ context }), [context]);

	return <PageStateProvider value={value}>{children}</PageStateProvider>;
}
