import { useCallback } from 'react';

import type { NavigateOptions } from 'react-router';

import { useUtilityContext } from '@change-corgi/core/react/utilityContext';

import { navigateExternal } from '../shared/navigateExternal';

export function useNavigateExternal(): (to: string, options?: Pick<NavigateOptions, 'replace' | 'state'>) => void {
	const { errorReporter } = useUtilityContext();

	return useCallback(
		(to, { replace, state } = {}) => {
			if (state) {
				void errorReporter.report({
					error: 'State is ignored when navigating to an external url',
					params: {
						target: to,
					},
				});
			}
			navigateExternal(to, { replace });
		},
		[errorReporter],
	);
}
