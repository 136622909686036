import getOr from 'lodash/fp/getOr';

import type { TranslationKeyArg, Translations } from '../shared/types';

export function translationExists(translations: readonly Translations[], key: TranslationKeyArg): boolean {
	const value = translations.reduce<string | Translations | null>(
		(acc, dic) => acc ?? getOr(null, key as string, dic),
		null,
	);
	return typeof value === 'string';
}
