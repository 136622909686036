import type { JSX } from 'react';

import type { Replacements, TranslationKeyArg } from '@change-corgi/core/i18n';

import { useI18n } from '../misc';

type Props = Readonly<{
	value: TranslationKeyArg;
	replacements?: Replacements;
}>;

export function Translate({ value, replacements }: Props): JSX.Element {
	const { translate } = useI18n();

	return <>{translate(value, replacements)}</>;
}
