import { isSsr } from '@change-corgi/core/ssr';
import { getWindow } from '@change-corgi/core/window';

import { BrowserOfflineError } from './errors';

type OptionMs = {
	/**
	 * if <= 0, wait time is infinite (use of waitTime: 'infinite' recommended instead)
	 *
	 * if undefined, function does not wait and throws right away if user is offline
	 */
	waitTimeInMs?: number;
};
type OptionLiteral = {
	waitTime?: 'infinite' | 'immediate';
};

type Options = OptionMs | OptionLiteral;

function normalizeWaitTime(options: Options = {}): number | undefined {
	if ((options as OptionLiteral).waitTime === 'infinite') return 0;
	if ((options as OptionLiteral).waitTime === 'immediate') return undefined;
	return (options as OptionMs).waitTimeInMs;
}

export async function ensureOnline(options: Options = {}): Promise<void> {
	if (isSsr()) return;

	const waitTimeInMs = normalizeWaitTime(options);

	const { navigator } = getWindow();

	// just in case the browser doesn't support navigator.onLine
	if (!('onLine' in navigator)) return;

	if (navigator.onLine) return;

	if (waitTimeInMs === undefined) {
		throw new BrowserOfflineError();
	}

	let listener: undefined | (() => void);
	let timeout: undefined | number;

	/* eslint-disable promise/avoid-new, promise/param-names */
	const onlinePromise = new Promise((resolve) => {
		listener = () => {
			timeout && window.clearTimeout(timeout);
			resolve(undefined);
		};
		getWindow().addEventListener('online', listener);
	});

	if (waitTimeInMs === 0) {
		await onlinePromise;
		return;
	}

	const timeoutPromise = new Promise((_resolve, reject) => {
		timeout = window.setTimeout(() => {
			listener && getWindow().removeEventListener('online', listener);
			reject(new BrowserOfflineError());
		}, waitTimeInMs);
	});
	await Promise.race([onlinePromise, timeoutPromise]);
	/* eslint-enable promise/avoid-new, promise/param-names */
}
