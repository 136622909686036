import type { JSX } from 'react';

import { useSetPageState } from '../context';

type Props = Readonly<{
	name: string;
	value: unknown;
	persistent?: boolean;
}>;

export function SetPageStateValue({ name, value, persistent }: Props): JSX.Element | null {
	useSetPageState(name, value, { persistent });

	return null;
}
