/* eslint-disable eslint-comments/no-unlimited-disable */
/* eslint-disable */

import { getWindow } from '@change-corgi/core/window';

declare global {
	// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
	interface Window {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		optimizely?: any;
	}
}

export function getOptimizelyInstance(): any {
	const win = getWindow();
	(function (o: any) {
		o.optimizely = o.optimizely || [];
	})(win);
	return win.optimizely;
}

export function genOptimizelyInitScript(): string {
	return `
		(function (o) {
			o.optimizely = o.optimizely || []
		})(window);
	`;
}

export function genOptimizelyTrackingScript(optIn: boolean): string {
	return `
		(function (o) {
			o.optimizely.push({ type: 'optOut',	isOptOut: ${!optIn} })
		})(window);
	`;
}

export function genOptimizelyScriptUrl(projectId: string): string {
	return `https://cdn.optimizely.com/js/${projectId}.js`;
}

export function genOptimizelyOnLoadScript(optIn: boolean): string {
	return `
		(function (o) {
			o.onOptimizelyLoad && o.onOptimizelyLoad(${optIn})
		})(window);
	`;
}
