import { CustomError } from 'ts-custom-error';

export class MissingTranslationError extends CustomError {
	readonly key: string;

	constructor({ key }: { key: string }) {
		super(`Missing Translation: ${key}`);
		this.key = key;
	}
}
