import { useCallback, useContext } from 'react';

import type { To } from '@remix-run/router';
import { createPath } from '@remix-run/router';
import type { NavigateOptions } from 'react-router';
import { UNSAFE_NavigationContext, useNavigate as useNavigateRouter } from 'react-router';

import { isExternalHttpUrl } from '@change-corgi/core/navigation';
import { useUtilityContext } from '@change-corgi/core/react/utilityContext';

import { useStaticRouterContext } from '../staticContext/hook';

import { useNavigateExternal } from './useNavigateExternal';

export const toString = (to: To): string => (typeof to === 'string' ? to : createPath(to));

const SCHEME_REGEX = /^https?:\/\//;

function checkValidUrl(to: To) {
	const url = toString(to);

	if (url.startsWith('./') || url.startsWith('../')) {
		throw new Error('Relative programmatic navigation not yet supported');
	}

	if (!url.startsWith('/') && !SCHEME_REGEX.exec(url)) {
		throw new Error('Relative programmatic navigation not yet supported');
	}
}

type Options = NavigateOptions & {
	/**
	 * forces the navigation to be external/internal, ignoring the list of internal routes from the utility context
	 */
	forceMode?: 'internal' | 'external';
};

export function useNavigate(): (to: To, options?: Options) => void {
	const { navigator } = useContext(UNSAFE_NavigationContext);
	const navigateRouter = useNavigateRouter();
	const { navigation } = useUtilityContext();
	const staticRouterContext = useStaticRouterContext();
	// mostly useful for tests using the StaticRouterContext
	const forceInternalRouting = !!staticRouterContext?.forceInternalRouting;
	const navigateExternal = useNavigateExternal();

	return useCallback(
		(to: To, { forceMode, ...options } = {}) => {
			checkValidUrl(to);

			const externalUrl = navigation.getExternalUrl(toString(to), { forceMode });

			if (externalUrl && !forceInternalRouting) {
				navigateExternal(externalUrl, options);
				return;
			}

			// mostly useful for tests using the StaticRouterContext
			if (typeof to === 'string' && isExternalHttpUrl(to)) {
				if (options.replace) {
					navigator.replace(to, options.state, options);
				} else {
					navigator.push(to, options.state, options);
				}
				return;
			}
			navigateRouter(to, options);
		},
		[navigateRouter, navigateExternal, navigation, navigator, forceInternalRouting],
	);
}
