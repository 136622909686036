import type { JSX, PropsWithChildren } from 'react';

import isEqual from 'lodash/isEqual';
import { useCustomCompareMemo } from 'use-custom-compare';

import { TrackingContextProvider as TrackingContextProviderBase } from './context';
import { useTracking } from './hook';
import type { TrackingFn } from './types';

type Props = {
	properties: Record<string, string | boolean | number | readonly string[] | readonly number[] | undefined>;
	/**
	 * can be used to disable tracking within the context
	 *
	 * useful for accessing a page in a mode that shouldn't impact tracking (e.g. preview mode)
	 */
	disabled?: boolean;
};

function propsToHookDeps(
	properties: Record<string, string | boolean | number | readonly string[] | readonly number[] | undefined>,
) {
	// there is no guarantee in JS that order will stay consistent, so let's sort the entries
	return Object.entries(properties)
		.sort(([key1], [key2]) => (key1 < key2 ? -1 : 1))
		.reduce<unknown[]>((acc, [key, value]) => {
			acc.push(key, value);
			return acc;
		}, []);
}

function areDepsEqual(
	[track1, properties1, disabled1]: readonly [
		TrackingFn,
		Record<string, string | boolean | number | readonly string[] | readonly number[] | undefined>,
		boolean,
	],
	[track2, properties2, disabled2]: readonly [
		TrackingFn,
		Record<string, string | boolean | number | readonly string[] | readonly number[] | undefined>,
		boolean,
	],
): boolean {
	if (track1 !== track2) return false;
	if (disabled1 !== disabled2) return true;
	if (properties1 === properties2) return true;
	const flattenProperties1 = propsToHookDeps(properties1);
	const flattenProperties2 = propsToHookDeps(properties2);
	return (
		flattenProperties1.length === flattenProperties2.length &&
		// let's avoid triggering unnecessary re-renders when the actual values don't change
		flattenProperties1.every((value, index) => isEqual(value, flattenProperties2[index]))
	);
}

export function TrackingContextProvider({
	children,
	disabled = false,
	properties,
}: PropsWithChildren<Props>): JSX.Element {
	const track = useTracking();

	const wrappedTrack: TrackingFn = useCustomCompareMemo(
		() => async (name, additionalProperties) => {
			if (disabled) {
				return false;
			}
			return track(name, {
				...properties,
				...additionalProperties,
			});
		},
		[track, properties, disabled],
		areDepsEqual,
	);

	return <TrackingContextProviderBase value={wrappedTrack}>{children}</TrackingContextProviderBase>;
}
