import { DecodeRichMediaTransformer } from './DecodeRichMediaTransformer';
import { EncodeRichMediaTransformer } from './EncodeRichMediaTransformer';
import type { CreateRichMediaTransformers, RichMediaTransformerParams } from './types';

export function createRichMediaTransformers({
	assetBasePath,
}: RichMediaTransformerParams): CreateRichMediaTransformers {
	const decodeRichMediaTransformer = new DecodeRichMediaTransformer({ assetBasePath });
	const encodeRichMediaTransformer = new EncodeRichMediaTransformer({ assetBasePath });
	return {
		decodeRichMediaTransformer,
		encodeRichMediaTransformer,
	};
}
