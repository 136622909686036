// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export interface TreatedStore {
	isTreated: (id: string) => boolean;

	setTreated: (id: string) => void;
}

class TreatedStoreImpl implements TreatedStore {
	private treated: Record<string, true> = {};

	isTreated(id: string) {
		return !!this.treated[id];
	}

	setTreated(id: string) {
		this.treated[id] = true;
	}
}

export function createTreatedStore(): TreatedStore {
	return new TreatedStoreImpl();
}
