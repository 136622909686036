import qs from 'qs';

export function extractExperimentOverridesFromQueryString(
	search: string,
	{ ignoreQueryPrefix }: { ignoreQueryPrefix?: boolean } = {},
): Readonly<Record<string, string>> {
	const overrides = qs.parse(search, { allowDots: true, ignoreQueryPrefix }).exo;

	if (!overrides || typeof overrides === 'string' || Array.isArray(overrides)) return {};

	return Object.entries(overrides).reduce<Record<string, string>>((acc, [name, variant]) => {
		if (typeof variant !== 'string') return acc;
		return {
			...acc,
			[name]: variant,
		};
	}, {});
}
