import { Element } from 'domhandler';

import { ALLOWED_ATTR_CHARS, ALLOWED_ATTRS } from '../../constants';
import type { RichMediaTagTransformer } from '../../types';
import { validateString } from '../../validateString';

export const decodeTransformer: RichMediaTagTransformer = ({ assetBasePath } = {}) => {
	if (!assetBasePath) throw new Error('Asset base path required');

	const shouldTransformElement = (element: Element): boolean =>
		element.attributes.find((el) => el.name === 'data-type')?.value === 'img';

	const transformTagFn = (element: Element): Element => {
		if (!shouldTransformElement(element)) return element;
		const newTagName = 'img';
		const newAttributes = {
			...element.attributes.reduce<Record<string, string>>((newAttrs, attribute) => {
				const matchedName = ALLOWED_ATTRS.find((attrName) => `data-${attrName}` === attribute.name);
				if (matchedName && validateString(attribute.value, ALLOWED_ATTR_CHARS[matchedName])) {
					return {
						...newAttrs,
						[matchedName]: matchedName === 'src' ? `${assetBasePath}${attribute.value}` : attribute.value,
					};
				}
				return newAttrs;
			}, {}),
		};
		return new Element(newTagName, newAttributes, element.children);
	};

	const transformer = { tagName: 'change-media', transformTagFn };

	return { transformer };
};
