function genFn<T>(
	property: string | readonly string[] | ((err: Error) => T | undefined),
): (err: Error) => T | undefined {
	if (typeof property === 'function') return property;
	// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-return, @typescript-eslint/no-explicit-any
	if (typeof property === 'string') return (error: Error) => (error as any)[property] as T | undefined;
	return (error: Error) => {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access
		if (!property.every((prop) => prop in error)) return undefined;
		// eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access
		return Object.fromEntries(property.map((prop) => [prop, (error as any)[prop]])) as T;
	};
}

/**
 * Search a property in an error's attribute and the full causes chain
 *
 * - with a string => return the first value found
 * - with an array => return an object with all values found from the first error with all properties
 * - with a function => return the first value found using the function
 */
function findPropertyInErrorStack<T = unknown>(err: unknown, property: string): T | undefined;
function findPropertyInErrorStack<K extends string, T extends Record<K, unknown>>(
	err: unknown,
	property: readonly K[],
): T | undefined;
function findPropertyInErrorStack<T = unknown>(err: unknown, property: (err: Error) => T | undefined): T | undefined;
function findPropertyInErrorStack<T = unknown>(
	err: unknown,
	property: string | readonly string[] | ((err: Error) => T | undefined),
): T | undefined {
	const fn = genFn<T>(property);
	let error = err;
	while (error instanceof Error) {
		const value = fn(error);
		if (value) return value;
		error = error.cause;
	}
	return undefined;
}

export { findPropertyInErrorStack };
