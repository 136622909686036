import mapKeys from 'lodash/fp/mapKeys';

import type { Session } from '@change-corgi/core/session';

export function getAppStatePageStateData({
	session,
	locale,
	countryCode,
}: {
	session?: Session;
	locale: string;
	countryCode: string;
}): unknown {
	return {
		locale,
		countryCode,
		utmParams:
			(session && mapKeys((key) => key.replace(/_(.)/g, (_m, g: string) => g.toUpperCase()), session.trackingData)) ||
			{},
	};
}
