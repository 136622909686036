import type { Options as GetCsrfTokenOptions } from './getCsrfToken';
import { getCsrfToken as getCsrfTokenFromApi } from './getCsrfToken';

type FetchPolicy = 'immediate' | 'as-needed';

type Options = GetCsrfTokenOptions & {
	/**
	 * Initial token value to initialize the cache with (for instance coming from SSR or session API call)
	 */
	initialCsrfToken?: string;
	/**
	 * Determines when the actual fetch request will be made:
	 *
	 * - `immediate`: if not in cache, the CSRF token will be retrieved right away when init() and refreshCsrfToken() are called
	 * - `as-needed`: if not in cache, the CSRF token will be retrieved only when requested
	 *
	 * default is `as-needed`
	 */
	fetchPolicy?: FetchPolicy;
	/**
	 * Time (in ms) after which the cache should be invalidated
	 *
	 * if 0, cache is never automatically invalidated
	 *
	 * default is 30 min
	 */
	cacheExpirationMs?: number;
};

// 45 min on the server => let's limit it to 30 to be sure
// https://github.com/change/fe/blob/452acc42a5982dd597ea47ff1015aa78e9211550/shared/server/middleware/session.js#L20
// TODO: any API call should reset that expiration delay, so this could be improved
const CSRF_EXPIRATION_LIMIT_DEFAULT = 30 * 60 * 1000; // 30 min

/* eslint-disable @typescript-eslint/consistent-type-definitions, @typescript-eslint/method-signature-style */
interface CsrfUtils {
	init(): this;
	getCsrfToken(options?: { nocache?: boolean }): Promise<string>;
	refreshCsrfToken(newToken?: string): void;
}
/* eslint-enable @typescript-eslint/consistent-type-definitions, @typescript-eslint/method-signature-style */

class CsrfUtilsImpl {
	private prevCsrfPromise: Promise<string> | undefined;
	private csrfPromise: Promise<string> | undefined;
	private getCsrfTokenOptions: GetCsrfTokenOptions;
	private fetchPolicy: FetchPolicy;
	private cacheExpirationMs: number;

	constructor({ initialCsrfToken, fetchPolicy, cacheExpirationMs, ...getCsrfTokenOptions }: Options) {
		this.getCsrfTokenOptions = getCsrfTokenOptions;
		this.fetchPolicy = fetchPolicy || 'as-needed';
		this.cacheExpirationMs = cacheExpirationMs ?? CSRF_EXPIRATION_LIMIT_DEFAULT;
		this.csrfPromise = initialCsrfToken ? Promise.resolve(initialCsrfToken) : undefined;
	}

	private timeout: number | undefined;
	private resetTimeout() {
		if (this.cacheExpirationMs <= 0) return;

		this.timeout && window.clearTimeout(this.timeout);
		this.timeout = window.setTimeout(() => {
			this.clearCache();
		}, this.cacheExpirationMs);
	}

	init() {
		if (!this.csrfPromise) {
			if (this.fetchPolicy === 'immediate') {
				void this.refreshPromise();
			}
		}

		this.resetTimeout();

		return this;
	}

	private clearCache() {
		// making sure to request a fresh token next time getCsrfToken() is called
		this.csrfPromise = undefined;
		this.resetTimeout();
	}

	private async refreshPromise(): Promise<string> {
		this.prevCsrfPromise = this.csrfPromise || this.prevCsrfPromise;
		const prevPromise = this.prevCsrfPromise;
		const internalCsrfPromise = getCsrfTokenFromApi(this.getCsrfTokenOptions);
		this.csrfPromise = (async () => {
			const csrfToken = await internalCsrfPromise;
			if (csrfToken) return csrfToken;
			if (prevPromise) return prevPromise;
			return '';
		})();

		// if the API call returns en empty token, reset the cache promise
		// so that next call to getCsrfToken() triggers a new API call
		const { csrfPromise } = this;
		void (async () => {
			const csrfToken = await internalCsrfPromise;
			if (!csrfToken && this.csrfPromise === csrfPromise) {
				this.csrfPromise = undefined;
			}
		})();

		this.resetTimeout();

		return this.csrfPromise;
	}

	async getCsrfToken(options: { nocache?: boolean } = {}) {
		if (options.nocache || !this.csrfPromise) {
			return this.refreshPromise();
		}

		return this.csrfPromise;
	}

	refreshCsrfToken(newToken?: string) {
		// is newToken is empty, set promise to undefined to make sure to request a fresh token in "immediate" mode
		this.csrfPromise = newToken ? Promise.resolve(newToken) : undefined;
		this.init();
	}
}

export type { CsrfUtils };

export function createCsrfUtils(options: Options): CsrfUtils {
	return new CsrfUtilsImpl(options);
}

export function createCsrfUtilsFake(errorMsg: string): CsrfUtils {
	const errorFn = () => {
		throw new Error(errorMsg);
	};
	return {
		init: errorFn,
		getCsrfToken: errorFn,
		refreshCsrfToken: errorFn,
	};
}
