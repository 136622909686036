import type { JSX } from 'react';

import type { I18n, Replacements, TranslationKeyArg } from '@change-corgi/core/i18n';
import type { HtmlParserReplaceFn, ParseHtmlOptions } from '@change-corgi/core/react/html';
import { parseHtml } from '@change-corgi/core/react/html';

type JsxReplacement = string | number | JSX.Element;
export type JsxReplacements = Readonly<Record<string, JsxReplacement>>;

export type { HtmlParserReplaceFn as TranslateHtmlReplaceFn };

function isJsx(replacement: JsxReplacement): replacement is JSX.Element {
	return typeof replacement !== 'string' && typeof replacement !== 'number';
}

function transformReplacements(replacements?: JsxReplacements) {
	const replacementsList = Object.entries(replacements || {}).map(([name, value], index) => ({
		name,
		value,
		tagName: `i18n-elt-${index}`,
	}));
	const actualReplacements = replacementsList.reduce<Replacements>(
		(acc, { tagName, name, value }) => ({ ...acc, [name]: isJsx(value) ? `<${tagName}></${tagName}>` : value }),
		{},
	);
	const tagReplacements = replacementsList.reduce<JsxReplacements>(
		(acc, { tagName, value }) => ({ ...acc, [tagName]: value }),
		{},
	);
	const tagList = replacementsList.map(({ tagName }) => tagName);
	return { actualReplacements, tagReplacements, tagList };
}

export type TranslateHtmlOptions = Pick<ParseHtmlOptions, 'replace'> & {
	/**
	 * Allow "target" attribute for anchors
	 *
	 * Opt-in and not default due to tab-nabbing security risks
	 *
	 * see also https://github.com/cure53/DOMPurify/pull/325#issuecomment-472361655
	 */
	allowLinkTargetAttr?: boolean;
};

function parse(
	html: string,
	tagList: string[],
	tagReplacements: JsxReplacements,
	{ replace, allowLinkTargetAttr }: TranslateHtmlOptions = {},
) {
	return parseHtml(html, {
		replace: (element, context) => {
			if (element.name in tagReplacements) {
				return tagReplacements[element.name] as JSX.Element;
			}
			return replace && replace(element, context);
		},
		allowedTags: tagList,
		allowedAttrs: allowLinkTargetAttr ? ['target'] : [],
	});
}

export type TranslateHtmlFn = (
	key: TranslationKeyArg,
	replacements?: JsxReplacements,
	options?: TranslateHtmlOptions,
) => ReturnType<typeof parseHtml>;
export type TranslatePluralHtmlFn = (
	key: TranslationKeyArg,
	count: number,
	replacements?: JsxReplacements,
	options?: TranslateHtmlOptions,
) => ReturnType<typeof parseHtml>;

export function translateHtml(
	translate: I18n['translate'],
	key: TranslationKeyArg,
	replacements?: JsxReplacements,
	options?: TranslateHtmlOptions,
): ReturnType<typeof parseHtml> {
	const { actualReplacements, tagReplacements, tagList } = transformReplacements(replacements);
	const html = translate(key, actualReplacements);
	return parse(html, tagList, tagReplacements, options);
}

// eslint-disable-next-line max-params
export function translatePluralHtml(
	translatePlural: I18n['translatePlural'],
	key: TranslationKeyArg,
	count: number,
	replacements?: JsxReplacements,
	options?: TranslateHtmlOptions,
): ReturnType<typeof parseHtml> {
	const { actualReplacements, tagReplacements, tagList } = transformReplacements(replacements);
	const html = translatePlural(key, count, actualReplacements);
	return parse(html, tagList, tagReplacements, options);
}
