import type { JSX, MouseEvent } from 'react';
import { forwardRef, useCallback } from 'react';

import omit from 'lodash/fp/omit';
import { useHref } from 'react-router';
import type { LinkProps as BaseLinkProps } from 'react-router-dom';
// eslint-disable-next-line no-restricted-imports
import { Link as BaseLink } from 'react-router-dom';

import { isExternalHttpUrl } from '@change-corgi/core/navigation';
import { useUtilityContext } from '@change-corgi/core/react/utilityContext';

import { useNavigateExternal } from '../hooks/useNavigateExternal';
import { shouldNavigate } from '../shared/link-click';
import { getLinkRel } from '../shared/rel';

// remove non-html attributes from LinkProps and RouteComponentProps
const pickExternalLinkProps = omit(['theme', 'location', 'match', 'replace', 'state', 'href', 'children', 'to']);

type ExternalProps = Readonly<{
	externalUrl?: string;
}>;

const ExternalLink = forwardRef<HTMLAnchorElement, BaseLinkProps & ExternalProps>(function ExternalLink(
	{ externalUrl, onClick: onClickInitial, ...props }: BaseLinkProps & ExternalProps,
	ref,
): JSX.Element | null {
	// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
	const { replace, state, children, ...rest } = props;
	const navigateExternal = useNavigateExternal();

	const onClick = useCallback(
		(event: MouseEvent<HTMLAnchorElement>) => {
			onClickInitial?.(event);
			if (externalUrl && shouldNavigate(event)) {
				event.preventDefault();
				// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
				navigateExternal(externalUrl, { replace: !!replace, state });
			}
		},
		[onClickInitial, navigateExternal, externalUrl, replace, state],
	);

	return (
		// eslint-disable-next-line react/forbid-elements
		<a href={externalUrl} onClick={onClick} ref={ref} {...pickExternalLinkProps(rest)}>
			{children}
		</a>
	);
});

export type LinkProps = BaseLinkProps &
	Readonly<{
		/**
		 * forces the navigation to be external/internal, ignoring the list of internal routes from the utility context
		 */
		forceMode?: 'internal' | 'external';
	}>;

/**
 * Wrapper of react-router's Link to handle external navigation
 */
export const Link = forwardRef<HTMLAnchorElement, LinkProps>(function Link(
	{ forceMode, ...props }: LinkProps,
	ref,
): JSX.Element | null {
	const { to } = props;

	const resolvedHref = useHref(to);
	const { errorReporter, navigation } = useUtilityContext();
	const href = typeof to === 'string' && isExternalHttpUrl(to) ? to : resolvedHref;

	try {
		const externalUrl = navigation.getExternalUrl(href, { forceMode });

		const { children, ...rest } = props;

		if (externalUrl) {
			return (
				<ExternalLink externalUrl={externalUrl} rel={getLinkRel(props)} ref={ref} {...rest}>
					{children}
				</ExternalLink>
			);
		}

		return (
			<BaseLink rel={getLinkRel(props)} ref={ref} {...rest}>
				{children}
			</BaseLink>
		);
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (e: any) {
		const { children, ...rest } = props;

		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		void errorReporter.report({ error: e, params: { target: to, component: 'Link' } });

		return (
			<ExternalLink rel={getLinkRel(props)} ref={ref} {...rest}>
				{children}
			</ExternalLink>
		);
	}
});
