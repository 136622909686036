import type { GraphQLRequest } from '@apollo/client';

type GqlDetails = {
	batched: boolean;
	operationNames: readonly string[];
};

function isGraphQLRequestBatch(
	request: GraphQLRequest | readonly GraphQLRequest[],
): request is readonly GraphQLRequest[] {
	return Array.isArray(request);
}

export function getRequestGqlDetails(init?: RequestInit): GqlDetails | undefined {
	if (!init?.body) return undefined;
	if (typeof init.body !== 'string') return undefined;
	try {
		const body = JSON.parse(init.body) as GraphQLRequest | readonly GraphQLRequest[];
		if (isGraphQLRequestBatch(body)) {
			return {
				batched: true,
				operationNames: body.map(({ operationName }) => operationName || 'unknown'),
			};
		}
		return {
			batched: false,
			operationNames: [body.operationName || 'unknown'],
		};
	} catch (e) {
		return undefined;
	}
}
