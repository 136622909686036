import { createMemoryHistory as createMemoryHistoryBase } from '@remix-run/router';
import type { MemoryHistory } from '@remix-run/router';

/**
 * wrapper around @remix-run/router's createMemoryHistory that allows for multiple calls to listen()
 */
export function createMemoryHistory(options?: Parameters<typeof createMemoryHistoryBase>[0]): MemoryHistory {
	const historyBase = createMemoryHistoryBase(options);
	const listeners: Array<Parameters<MemoryHistory['listen']>[0]> = [];
	let globalListenerCleanup: (() => void) | undefined;
	return {
		get index() {
			return historyBase.index;
		},
		get action() {
			return historyBase.action;
		},
		get location() {
			return historyBase.location;
		},
		createHref: historyBase.createHref.bind(historyBase),
		createURL: historyBase.createURL.bind(historyBase),
		encodeLocation: historyBase.encodeLocation.bind(historyBase),
		push: historyBase.push.bind(historyBase),
		replace: historyBase.replace.bind(historyBase),
		go: historyBase.go.bind(historyBase),
		listen(fn) {
			if (!listeners.includes(fn)) {
				if (!globalListenerCleanup) {
					globalListenerCleanup = historyBase.listen((args) => {
						// making a temporary copy of listeners because it could itself be updated by a listener...
						[...listeners].forEach((listener) => listener(args));
					});
				}

				listeners.push(fn);
			}

			return () => {
				if (!listeners.includes(fn)) return;

				listeners.splice(listeners.indexOf(fn), 1);

				if (!listeners.length && globalListenerCleanup) {
					globalListenerCleanup();
					globalListenerCleanup = undefined;
				}
			};
		},
	};
}
