import type { Session } from '@change-corgi/core/session';

export function getViewerPageStateData(session: Session | undefined): unknown {
	if (!session) {
		return undefined;
	}

	const { user, loginState, uuid, experiments } = session;

	const experimentsMap =
		experiments &&
		experiments.reduce<Record<string, unknown>>((acc, { id, ...rest }) => {
			// eslint-disable-next-line no-param-reassign
			acc[id] = rest;
			return acc;
		}, {});

	return {
		user: user
			? {
					id: user.id,
					displayName: user.displayName,
					photo: user.photo,
					totalSignatureCount: user.totalSignatureCount,
				}
			: undefined,
		loginState,
		uuid,
		activity: user?.activityRaw || undefined,
		experiments: experimentsMap,
	};
}
