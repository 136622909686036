import { CustomError } from 'ts-custom-error';

export class MissingReplacementsError extends CustomError {
	readonly key: string;
	readonly translation: string;
	readonly finalTranslation: string;
	readonly missingReplacements: readonly string[];

	constructor({
		key,
		translation,
		finalTranslation,
		missingReplacements,
	}: {
		key: string;
		translation: string;
		finalTranslation: string;
		missingReplacements: readonly string[];
	}) {
		super(`Missing Replacements: ${missingReplacements.join(', ')} for "${translation}"`);
		this.key = key;
		this.translation = translation;
		this.finalTranslation = finalTranslation;
		this.missingReplacements = missingReplacements;
	}
}
