import type { MouseEvent } from 'react';

function isModifiedEvent(event: MouseEvent<HTMLAnchorElement>) {
	return !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey);
}

// inspired by https://github.com/ReactTraining/react-router/blob/v5.1.2/packages/react-router-dom/modules/Link.js#L40
export function shouldNavigate(event: MouseEvent<HTMLAnchorElement>): boolean {
	return (
		!event.defaultPrevented && // onClick prevented default
		event.button === 0 && // ignore everything but left clicks
		(!event.currentTarget.target || event.currentTarget.target === '_self') && // let browser handle "target=_blank" etc.
		!isModifiedEvent(event) && // ignore clicks with modifier keys
		!event.currentTarget.getAttribute('download') // let browser handle downloads
	);
}
