import { getDocument } from '@change-corgi/core/window';

export async function addScript(src: string): Promise<void> {
	// eslint-disable-next-line promise/avoid-new
	return new Promise((resolve, reject) => {
		const document = getDocument();

		const parent = document.head;

		const script = document.createElement('script');
		script.onerror = (_event) => {
			parent.removeChild(script);
			reject(new Error(`The script ${src} didn't load correctly.`));
		};

		script.onload = () => {
			resolve();
		};

		script.async = true;
		script.src = src;

		parent.appendChild(script);
	});
}
