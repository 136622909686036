import { getExternalUrl } from '@change-corgi/core/navigation';

type Options = Readonly<{
	internalRoutes: readonly RegExp[];
}>;

type NavigationUtils = {
	getExternalUrl: (url: string, options?: { forceMode?: 'internal' | 'external' }) => string | undefined;
};

class NavigationUtilsImpl implements NavigationUtils {
	private readonly internalRoutes: readonly RegExp[];

	constructor({ internalRoutes }: Options) {
		this.internalRoutes = internalRoutes;
	}

	/**
	 * Returns an url as a string if pointing to an external app/website, otherwise undefined
	 */
	getExternalUrl(url: string, options?: { forceMode?: 'internal' | 'external' }): string | undefined {
		return getExternalUrl(url, { ...options, internalRoutes: this.internalRoutes });
	}
}

export type { NavigationUtils };

export function createNavigationUtils(options: Options): NavigationUtils {
	return new NavigationUtilsImpl(options);
}

export function createNavigationUtilsFake(errorMsg: string): NavigationUtils {
	const errorFn = () => {
		throw new Error(errorMsg);
	};
	return {
		getExternalUrl: errorFn,
	};
}
