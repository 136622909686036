import type { JSX } from 'react';

import type { Replacements } from '@change-corgi/core/i18n';

import { useI18n } from '../misc';

type Props = Readonly<{
	value: string;
	count: number;
	replacements?: Replacements;
}>;

export function TranslatePlural({ value, count, replacements }: Props): JSX.Element {
	const { translatePlural } = useI18n();

	return <>{translatePlural(value, count, replacements)}</>;
}
