export function addQueryParam(url: string, paramName: string, paramValue: string): string {
	// handling 2 different cases to avoid unsafe regex
	if (/^[^#]+\?/.exec(url)) {
		return url.replace(
			/(\?[^#]+)(?=#|$)/,
			(_m, query: string) => `${query}&${paramName}=${encodeURIComponent(paramValue)}`,
		);
	}

	return url.replace(/(#|$)/, (_m, hash: string) => `?${paramName}=${encodeURIComponent(paramValue)}${hash}`);
}
