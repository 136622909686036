import { getWindow } from '@change-corgi/core/window';

import { VARIABLE_NAME } from './consts';
import type { PageState, State } from './PageState';
import { createPageState } from './PageState';

export function createWindowPageState(): PageState {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const win = getWindow() as any as Record<string, unknown>;
	return createPageState({
		set: (state) => {
			win[VARIABLE_NAME] = state;
		},
		get: () => (win[VARIABLE_NAME] || {}) as State,
	});
}
