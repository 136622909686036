// using experiments for backward compatibility with fe's behavior

import type { Experiments } from '@change-corgi/core/experiments';

import type { TrackingEvent } from './types';

export function isLoggingEnabled(overrides: Experiments['overrides'], def: boolean): boolean {
	const exp = overrides.get<'enabled'>('event_logging');
	if (exp === undefined) {
		return def;
	}
	return exp === 'enabled';
}

export function toggleLogging(overrides: Experiments['overrides'], enabled: boolean): void {
	if (enabled) {
		overrides.set<'enabled'>('event_logging', 'enabled');
		return;
	}
	overrides.unset('event_logging');
}

export function logEvents(events: readonly TrackingEvent[], common: Record<string, unknown>, silenced: boolean): void {
	events.forEach((event) => {
		/* eslint-disable no-console */
		console.groupCollapsed(`%cTRACKED EVENT:%c ${event.name}`, 'color: green', 'color: default');
		console.log('event data:', event.data);
		console.log('full data:', { ...common, ...event.data });
		console.log('silenced:', silenced);
		console.groupEnd();
		/* eslint-enable no-console */
	});
}
