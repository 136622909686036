import type { TreatedStore } from './TreatedStore';

export type Experiment<VARIATION extends string = string> = {
	readonly id: string;
	readonly variation: VARIATION;
	readonly treated: boolean;
};

export type TreatableExperiment<VARIATION extends string = string> = Experiment<VARIATION> & {
	readonly overriden: boolean;
	/**
	 * original experiment data when overriden
	 */
	readonly originalExperiment?: Experiment<VARIATION>;
	treat: () => Promise<void>;
};

export function createDummyExperiment<VARIATION extends string = string>(
	id: string,
	variation?: VARIATION,
): TreatableExperiment<VARIATION> {
	return {
		id,
		variation: variation || 'control',
		treated: true,
		overriden: true,
		async treat() {
			return Promise.resolve();
		},
	} as TreatableExperiment<VARIATION>;
}

export function createTreatableExperiment<VARIATION extends string = string>(
	experiment: Experiment<VARIATION>,
	override: VARIATION | undefined,
	treatedStore: TreatedStore,
): TreatableExperiment<VARIATION> {
	return {
		id: experiment.id,
		variation: override || experiment.variation,
		get treated() {
			return override ? true : experiment.treated || treatedStore.isTreated(experiment.id);
		},
		overriden: !!override,
		originalExperiment: experiment,
		// treatment is done in experiment.ts
		async treat() {
			return Promise.resolve();
		},
	} as TreatableExperiment<VARIATION>;
}
