import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { HttpLink } from '@apollo/client/link/http';
import mapKeys from 'lodash/mapKeys';

export type HttpLinkOptions = Readonly<{
	uri: string;
	headers?: Readonly<Record<string, string>>;
	fetch?: typeof fetch;
}>;

export type HttpLinks = Readonly<{ single: HttpLink; batch: BatchHttpLink }>;

export function getHttpLinks({ uri, headers, fetch }: HttpLinkOptions): HttpLinks {
	const config = {
		uri,
		credentials: 'same-origin',
		headers: {
			// eslint-disable-next-line @typescript-eslint/naming-convention
			'x-requested-with': 'http-link',
			...toLowerKeys(headers),
		},
		fetch,
		fetchOptions: {
			method: 'POST',
		},
		includeExtensions: true,
	};
	return {
		single: new HttpLink(config),
		batch: new BatchHttpLink(config),
	};
}

const toLowerKeys = (obj: Readonly<Record<string, string>> | undefined) =>
	obj ? mapKeys(obj, (_v, k) => k.toLowerCase()) : undefined;
