import { addScript } from '@change-corgi/core/dom';
import { getWindow } from '@change-corgi/core/window';

import type { GSIFunctions, InitParams, Window } from './types';

const GOOGLE_SIGNIN_SDK_URL = 'https://accounts.google.com/gsi/client';

export async function initGoogleSignInSDK({ reportError }: InitParams): Promise<GSIFunctions | undefined> {
	try {
		await addScript(GOOGLE_SIGNIN_SDK_URL);
	} catch (e) {
		void (async () => {
			try {
				const response = await fetch(GOOGLE_SIGNIN_SDK_URL);
				if (response.ok) {
					reportError({
						error: new Error('Could not run Google Sign In script'),
					});
				} else {
					// server errors
					reportError({
						error: new Error('Could not retrieve Google Sign In script'),
						params: {
							message: response.statusText,
							status: response.status,
							url: GOOGLE_SIGNIN_SDK_URL,
						},
					});
				}
			} catch (error) {
				// request failed
				reportError({
					error: new Error('Could not retrieve Google Sign In script'),
					params: {
						message: String(error),
						url: GOOGLE_SIGNIN_SDK_URL,
					},
				});
			}
		})();
		reportError(e as Error);
	}

	const initializeGSI = (): GSIFunctions | undefined => {
		const window = getWindow() as unknown as Window;
		return !window.google?.accounts?.id
			? undefined
			: { initialize: window.google.accounts.id.initialize, renderButton: window.google.accounts.id.renderButton };
	};
	return initializeGSI();
}
