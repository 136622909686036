import type { HtmlTransformer } from '@change-corgi/core/react/html';
import { decodeChangeTags } from '@change-corgi/core/richMediaTags';

import type { RichMediaTransformerParams } from './types';

export class DecodeRichMediaTransformer implements HtmlTransformer {
	private readonly assetBasePath: string;

	constructor({ assetBasePath }: RichMediaTransformerParams) {
		this.assetBasePath = assetBasePath;
	}

	transformHtml = (html: string): string => decodeChangeTags(html, { assetBasePath: this.assetBasePath });
	readonly transformedTags = ['change-media'];
	readonly transformedAttrs = ['data-alt', 'data-height', 'data-src', 'data-type', 'data-width', 'target'];
}
