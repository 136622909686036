import type { ReportableError } from '@change-corgi/core/errorReporter/common';
import type { HttpClient } from '@change-corgi/core/http';
import { addQueryParam, retry } from '@change-corgi/core/http';

import type { Translations } from './shared/types';
import { getTranslationsUrl } from './url';

type Options = Readonly<{
	getAsset: HttpClient['getAsset'];
	reportError: (error: ReportableError) => void;
	/**
	 * can be used to solve CORS issues when switching between environments
	 */
	cacheBuster?: string;
	/**
	 * custom translations URL
	 */
	i18nUrl?: string;
}>;

const RETRY_ATTEMPTS = 3;
const RETRY_DELAY = 250;

export async function getTranslations(
	locale: string,
	{ getAsset, reportError, cacheBuster, i18nUrl: overrideI18nUrl }: Options,
): Promise<Translations> {
	const i18nUrl = overrideI18nUrl || getTranslationsUrl(locale, cacheBuster);
	// use getAsset to avoid sending custom headers, which seem to add 500ms of queuing to the request (at least in Chrome)
	return (
		retry({
			task: async () => getAsset<Translations>(i18nUrl),
			onRetry: async () => getAsset<Translations>(addQueryParam(i18nUrl, 'retrycb', String(Date.now()))),
			attempts: RETRY_ATTEMPTS,
			delay: RETRY_DELAY,
		})
			// eslint-disable-next-line promise/prefer-await-to-then
			.catch((response) => {
				reportError({
					/* eslint-disable @typescript-eslint/no-unsafe-member-access,@typescript-eslint/restrict-template-expressions */
					error: new Error(`Failed to retrieve i18n file after ${RETRY_ATTEMPTS} attempts with ${RETRY_DELAY}ms delay`),
					params: response.status
						? {
								message: response.statusText,
								status: response.status,
								url: i18nUrl,
								locale,
							}
						: {
								// eslint-disable-next-line @typescript-eslint/no-unsafe-call
								message: response.toString(),
								url: i18nUrl,
								locale,
							},
					/* eslint-enable @typescript-eslint/no-unsafe-member-access,@typescript-eslint/restrict-template-expressions */
				});
				throw response;
			})
	);
}
