import type { CurrencyOptions, NumberFormatOptions } from '../shared/types';

import { isZeroDecimalCurrency } from './isZeroDecimalCurrency';

function getDecimalAmount(amount: number, currency: string, amountInBaseUnits: boolean | undefined): number {
	if (amountInBaseUnits) {
		return isZeroDecimalCurrency(currency) ? amount : amount / 100;
	}
	return amount;
}

export function localizeCurrency(
	locale: string,
	amount: number,
	currency: string,
	{ amountInBaseUnits, currencyDisplay, currencySign, forceFractionDigits: showFractionDigits }: CurrencyOptions = {},
): string {
	let options: NumberFormatOptions = {
		style: 'currency',
		currency,
		currencyDisplay,
		currencySign,
	};
	const value = getDecimalAmount(amount, currency, amountInBaseUnits);
	if (!showFractionDigits) {
		// using parts to avoid getting something like $42.3
		const parts = new Intl.NumberFormat(locale, options).formatToParts(value);
		if (parts.find(({ type }) => type === 'fraction')?.value === '00') {
			options = { ...options, minimumFractionDigits: 0, maximumFractionDigits: 0 };
		}
	}

	return new Intl.NumberFormat(locale, options).format(value);
}
